function initialState() {
  return {
    seller_id: null,
    logotipoUrl: null,
    fantasyName: null,
    domain: null,
    subdomain: null,
    username: null,
    pathName: null,
    primaryColor: null,
    permissions: false,
    configuration: null,
  };
}

export function sellerData(state = initialState(), action) {
  switch (action.type) {
    case 'SET_SELLER':
      return {
        ...state,
        seller_id: action.seller_id,
        logotipoUrl: action.url,
        fantasyName: action.nome_fantasia,
        domain: action.dominio,
        username: action.username,
        pathName: action.pathName,
        primaryColor: action.primary_color,
        permissions: action.permissions,
        configuration: action.configuration,
      };

    default:
      return state;
  }
}
