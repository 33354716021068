function initialState() {
  return { buildHash: null };
}

export function buildHash(state = initialState(), action) {
  switch (action.type) {
    case 'SET_BUILD_HASH':
      return { ...state, buildHash: action.buildHash };
    default:
      return state;
  }
}
