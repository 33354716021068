import {
  Skeleton,
  Card,
  Drawer,
  Button,
  Form,
  Row,
  Col,
  Input,
  message,
  Typography,
  Progress
} from 'antd';
import React, { useEffect, useState } from 'react';
import { baseURL, axiosClient, createAuthenticatedEventSource } from '../../apiClient';
import { io } from 'socket.io-client';
import { useSelector } from 'react-redux';
const { Meta } = Card;

export default function OmieIntegrationManager() {
  const [form] = Form.useForm();
  const sellerData = useSelector((state) => state.sellerData);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [savingKeys, setSavingKeys] = useState(false);
  const [deletingKeys, setDeletingKeys] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [hasSavedKeys, setHasSavedKeys] = useState(false);
  const [deleteDrawerVisible, setDeleteDrawerVisible] = useState(false); // Estado para controle do Drawer de confirmação

  // Carrega chaves salvas no backend
  useEffect(() => {
    axiosClient
      .get('/integrations/omie/get-keys', { withCredentials: true })
      .then((response) => {
        const integrationData = response.data?.data?.integration;
        if (integrationData) {
          setHasSavedKeys(true);
          form.setFieldsValue({
            ...integrationData,
            description: response.data.data.description,
          });
        } else {
          setHasSavedKeys(false);
        }
        setLoading(false);
      });
  }, []);

  // Salva as chaves no backend
  const handleSaveKeys = () => {
    setSavingKeys(true);
    form.validateFields()
      .then((values) => {
        axiosClient
          .post('/integrations/omie/save-keys', values, { withCredentials: true })
          .then((response) => {
            const integrationData = response.data?.data?.integration;
            if (integrationData) {
              setHasSavedKeys(true);
              form.setFieldsValue({
                consumer_key: integrationData.consumer_key,
                consumer_secret: integrationData.consumer_secret,
                description: integrationData.description,
              });
              message.success('Chaves salvas com sucesso', 4);
            } else {
              setHasSavedKeys(false);
              message.error('Erro ao salvar chaves', 4);
            }
            setSavingKeys(false);
          });
      })
      .catch(() => setSavingKeys(false));
  };

  // Exclui as chaves no backend
  const handleDeleteKeys = () => {
    setDeletingKeys(true);
    axiosClient
      .get('/integrations/omie/delete-keys', { withCredentials: true })
      .then((response) => {
        if (response.data.status === 'Success') {
          setHasSavedKeys(false);
          form.resetFields(['consumer_key', 'consumer_secret']);
          message.success('Chaves deletadas com sucesso', 4);
        } else {
          message.error('Erro ao deletar chaves', 4);
        }
        setDeletingKeys(false);
      });
  };
  const handleSyncProducts = async () => {
    setProgress(1);
    message.loading('Sincronizando produtos...', 0);

    let page = 1;
    const products = [];

    try {
      while (true) {
        const response = await axiosClient.post(
          '/integrations/omie/import-products',
          { page },
          { withCredentials: true }
        );

        if (!response || !response.data) {
          throw new Error('Resposta inválida da API');
        }

        const { status, products: newProducts = [], total_pages: totalPages } = response.data;

        products.push(...newProducts);

        if (!totalPages || totalPages === 0) {
          throw new Error('Número total de páginas inválido');
        }

        setProgress(Math.min((page / totalPages) * 99, 99));

        if (status === "done" || page >= totalPages) break;

        page++;
      }

      await axiosClient.post('/integrations/omie/remove-products', { products }, { withCredentials: true });
      message.destroy();
      message.success('Produtos sincronizados!', 4);
      setProgress(100);
    } catch (error) {
      console.error('Erro durante a sincronização:', error);
      message.destroy();
      message.error('Erro ao sincronizar produtos', 4);
    }
  };

  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <Card
          style={{ width: 300 }}
          cover={
            <img
              alt="omie"
              style={{ width: '60%', margin: 'auto', marginTop: '10px' }}
              src="https://storage.googleapis.com/nupedido-public-bucket/static/images/integrations/omie.png"
            />
          }
          actions={[
            <Button onClick={() => setDrawerOpen(true)}>Gerenciar</Button>,
          ]}
        >
          <Meta
            title="Omie ERP"
            description="Integrar seu catálogo Nupedido com o gestor empresarial Omie ERP."
          />
        </Card>
      )}

      {/* Drawer para gerenciar integração */}
      <Drawer
        title="Gerenciamento de integração - Omie ERP"
        placement="bottom"
        width={500}
        height={window.innerWidth > 575 ? '360px' : '480px'}
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
      >
        {savingKeys ? (
          <Skeleton active />
        ) : (
          <Form layout="vertical" form={form}>
            <Row gutter={16}>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="consumer_key"
                  label="Consumer Key"
                  rules={[{ required: true, message: 'Por favor, insira o Consumer Key' }]}
                >
                  <Input placeholder="Consumer Key" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  name="consumer_secret"
                  label="Consumer Secret"
                  rules={[{ required: true, message: 'Por favor, insira o Consumer Secret' }]}
                >
                  <Input placeholder="Consumer Secret" />
                </Form.Item>
              </Col>
            </Row>

            {progress > 0 && (
              <Progress
                percent={progress.toFixed(2)}
                status={progress === 100 ? 'success' : 'active'}
                style={{ marginTop: 20 }}
              />
            )}
            {hasSavedKeys && (
              <Row gutter={16}>
                <Col xs={24} sm={8}>
                  <Form.Item
                    name="codigo_conta_corrente"
                    label="Código Conta Corrente"
                    rules={[{ required: true, message: 'Por favor, insira o Código Conta Corrente' }]}
                  >
                    <Input placeholder="Código Conta Corrente" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={8}>
                  <Form.Item
                    name="codigo_categoria"
                    label="Código Categoria"
                    rules={[{ required: true, message: 'Por favor, insira o Código Categoria' }]}
                  >
                    <Input placeholder="Código Categoria" />
                  </Form.Item>
                </Col>
              </Row>)}
            <Row justify="center" gutter={24}>
              <Col>
                {hasSavedKeys && (
                  <Button block onClick={() => setDeleteDrawerVisible(true)} loading={deletingKeys}>
                    Excluir Integração
                  </Button>
                )}
              </Col>
              <Col>
                <Button block type="primary" onClick={handleSaveKeys} loading={savingKeys}>
                  Salvar
                </Button>
              </Col>
              <Col>
                <Button
                  block
                  type="primary"
                  onClick={handleSyncProducts}
                  loading={loading}
                >
                  Sincronizar Produtos
                </Button>
              </Col>
              <Col>
                <Button
                  block
                  type="primary"
                  onClick={() => {
                    message.loading('Sincronizando situações...', 0);
                    axiosClient.get('/integrations/omie/sync-payment-conditions', { withCredentials: true })
                      .then((response) => {
                        message.destroy();
                        message.success('Condições Sincronizadas!', 4)
                      })
                  }}
                  loading={loading}
                >
                  Sincronizar Condições de Pagamento
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Drawer>

      {/* Drawer de Confirmação de Exclusão */}
      <Drawer
        title="Confirmar Exclusão"
        placement="bottom"
        onClose={() => setDeleteDrawerVisible(false)}
        open={deleteDrawerVisible}
        height={200}
      >
        <Row justify="center">
          <Col>
            <Typography.Title style={{ paddingBottom: 8, margin: 0 }} level={5}>
              Você tem certeza que deseja excluir as chaves de integração?
            </Typography.Title>
          </Col>
        </Row>
        <Row justify="center" gutter={24}>
          <Col>
            <Button onClick={() => setDeleteDrawerVisible(false)}>Cancelar</Button>
          </Col>
          <Col>
            <Button
              type="primary"
              danger
              onClick={() => {
                handleDeleteKeys();
                setDeleteDrawerVisible(false); // Fecha o Drawer após confirmar
              }}
            >
              Excluir
            </Button>
          </Col>
        </Row>
      </Drawer>
    </>
  );
}
