import React from 'react';
import {
  Layout,
  Typography
} from 'antd';
import { useSelector } from 'react-redux';


const { Footer } = Layout;
const { Text, Link } = Typography;

export default function PagesFooter() {
  const buildHash = useSelector((state) => state.buildHash);

  return (
    <Footer
      style={{
        textAlign: 'center',
      }}
    >
      <Text>
        Criado por{' '}
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href="https://nupedido.com.br/?source=admin"
        >
          Nupedido
        </Link>©{' '} 2024 - Versão {buildHash ? buildHash.buildHash : 'N/A'}
      </Text>
    </Footer>
  );
}
