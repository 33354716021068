import {
  Skeleton,
  Card,
  Drawer,
  Button,
} from 'antd';
// import { KeyOutlined, EllipsisOutlined } from "@ant-design/icons";
import React, { useEffect, useState, useRef } from 'react';
import styles from './index.module.css';
import { axiosClient } from '../../apiClient';

const { Meta } = Card;

export default function SellerIntegrationsMelhorEnvio() {
  const [loading, setLoading] = useState(true);
  const [waitingConf, setWaitingConf] = useState(false);
  const [melhorenvioUrl, setMelhorenvioUrl] = useState('#');
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [userClicked, setUserClicked] = useState(false);
  const [integrationStatus, setIntegrationStatus] = useState(false);

  const refreshToken = () => {
    setLoading(true);
    axiosClient
      .post(
        '/integrations/melhorenvio/refresh-token',
        { sellerUrl: window.location.origin },
        { withCredentials: true },
      )
      .then((response) => {
        setLoading(false);
        response.data.status === 'Success'
          ? setIntegrationStatus(true)
          : setIntegrationStatus(false);
        response.data.status === 'Error'
          && setMelhorenvioUrl(response.data.pagseguro_url);
      });
  };

  const revokeIntegration = () => {
    setLoading(true);
    axiosClient
      .get('/integrations/melhorenvio/revoke', { withCredentials: true })
      .then((response) => {
        refreshToken();
      });
  };

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const onClose = () => {
    setDrawerVisible(false);
    setUserClicked(false);
  };

  return (
    <>
      <Card
        style={{ width: 300 }}
        cover={(
          <img
            alt="melhorenvio"
            style={{
              width: '59%',
              margin: 'auto',
              marginTop: '10px',
            }}
            src="https://storage.googleapis.com/nupedido-public-bucket/static/images/integrations/melhorenvio.png"
          />
        )}
        actions={[
          <Button
            disabled
            onClick={() => {
              setUserClicked(true);
              showDrawer();
            }}
          >
            Em breve
            {/* Gerenciar */}
          </Button>,

        ]}
      >
        <Meta
          title="Melhor Envio"
          description="Disponibilize a cotação de fretes aos seus clientes por meio  da Melhor Envio."
        />
      </Card>
      <Drawer
        title="Gerenciamento de integração - Melhor Envio"
        placement="bottom"
        height={window.innerWidth > 575 ? '360px' : '480px'}
        onClose={onClose}
        open={drawerVisible}
      >
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            {!integrationStatus ? (
              <>
                Status: Desativado
                <br />
                <Button
                  href={melhorenvioUrl}
                  type="primary"
                  loading={waitingConf}
                >
                  Vincular Conta
                </Button>
              </>
            ) : (
              <>
                Status: Ativo
                <br />
                <Button
                  onClick={() => revokeIntegration()}
                  type="primary"
                  loading={waitingConf}
                >
                  Remover Integração
                </Button>
              </>
            )}
          </>
        )}

        {/* <Form layout="vertical" hideRequiredMark>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: 'Please enter user name' }]}
            >
              <Input placeholder="Please enter user name" />
            </Form.Item>
          </Col>
        </Row>
      </Form> */}
      </Drawer>
    </>
  );
}
