import React, { useState, useEffect } from 'react';
import { List, Typography, Tooltip, Button, Skeleton, Modal } from 'antd';
import { FilePdfOutlined, DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { axiosClient } from '../../apiClient';

const { Text } = Typography;

const SellerListInvoice = () => {
    const buyerView = useSelector((state) => state.buyerView.buyerView);
    const logged = useSelector((state) => state.authentication.logged);
    const [notasFiscais, setNotasFiscais] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modalPDFVisible, setModalPDFVisible] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [loadingPDF, setLoadingPDF] = useState(false);
    const [xmlBase64, setXmlBase64] = useState(null);

    useEffect(() => {
        if (buyerView || logged === 'buyer') {
            axiosClient
                .post(
                    '/payment/invoices',
                    { buyerId: buyerView ? buyerView.id : null },
                    { withCredentials: true },
                )
                .then((response) => {
                    setNotasFiscais(response.data.invoices);
                    setLoading(false);
                });
        }
    }, [buyerView, logged]);

    const openPDF = (base64) => {
        const byteCharacters = atob(base64);
        const byteNumbers = new Uint8Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const blob = new Blob([byteNumbers], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        setPdfUrl(url);
        setModalPDFVisible(true);
    };

    const downloadPDF = () => {
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_self';
        link.download = 'nota_fiscal.pdf';
        document.body.append(link);
        link.click();
        link.remove();
    };

    const downloadXML = () => {
        const link = document.createElement('a');
        link.href = `data:text/xml;base64,${xmlBase64}`;
        link.download = 'nota_fiscal.xml';
        document.body.append(link);
        link.click();
        link.remove();
    };

    return (
        <div>
            {loading ? <Skeleton /> : (
                <>
                    <List
                        itemLayout="horizontal"
                        dataSource={notasFiscais}
                        renderItem={item => (
                            <List.Item
                                actions={[
                                    <Tooltip title="Abrir pdf">
                                        <Button
                                            shape="round"
                                            type="circle"
                                            onClick={() => {
                                                setLoadingPDF(item.id);
                                                axiosClient
                                                    .post(
                                                        '/payment/invoice',
                                                        { buyerId: buyerView ? buyerView.id : null, invoiceId: item.id },
                                                        { withCredentials: true },
                                                    )
                                                    .then((response) => {
                                                        openPDF(response.data.danfe);
                                                        setXmlBase64(response.data.xml_nfe); // Save the XML base64
                                                    })
                                                    .finally(() => setLoadingPDF(false));
                                            }}
                                        >
                                            {loadingPDF === item.id ? (
                                                <LoadingOutlined />
                                            ) : (
                                                <FilePdfOutlined />
                                            )}
                                        </Button>
                                    </Tooltip>,
                                ]}
                            >
                                <List.Item.Meta
                                    title={<Text strong>Nota Fiscal Nº: {item.numero}</Text>}
                                    description={
                                        <>
                                            <Text>Valor: R$ {parseFloat(item.valor).toFixed(2)}</Text><br />
                                            <Text>Data de Emissão: {item.data_emissao}</Text><br />
                                        </>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                    <Modal
                        centered
                        closable
                        bodyStyle={{ height: '65vh' }}
                        width="90%"
                        title="Nota Fiscal"
                        footer={(
                            <>
                                <Button onClick={downloadPDF} type="primary">
                                    Download PDF <DownloadOutlined />
                                </Button>
                                <Button onClick={downloadXML} type="default" style={{ marginLeft: '10px' }}>
                                    Download XML <DownloadOutlined />
                                </Button>
                            </>
                        )}
                        open={modalPDFVisible}
                        onCancel={() => setModalPDFVisible(false)}
                        cancelText="Fechar"
                    >
                        <iframe
                            style={{ width: '100%', height: '60vh' }}
                            src={pdfUrl}
                            type="application/pdf"
                        />
                    </Modal>
                </>
            )}
        </div>
    );
};

export default SellerListInvoice;
