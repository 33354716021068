import React, { useEffect } from 'react';
import { List, Typography, Tag, Statistic, Row, Col, Skeleton } from 'antd';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

const { Text } = Typography;

const BuyerListBill = () => {
    const bills = useSelector((state) => state.bills.bills);

    const calculateSummary = (bills) => {
        const summary = {
            pago: { quantity: 0, value: 0 },
            aberto: { quantity: 0, value: 0 },
            parcial: { quantity: 0, value: 0 },
            atrasado: { quantity: 0, value: 0 },
        };

        if (!bills) return summary;

        bills.forEach(({ situacao, valor }) => {
            if (summary[situacao]) {
                summary[situacao].quantity += 1;
                summary[situacao].value += parseFloat(valor);
            }
        });

        return summary;
    };

    const summary = calculateSummary(bills);

    const renderStatistic = (title, data, color, icon) => (
        <Col span={6}>
            <Statistic
                title={<Text style={{ fontSize: '11px' }}>{title}</Text>}
                value={data.quantity}
                prefix={icon}
                valueStyle={{ color, fontSize: '18px' }}
            />
            <Text style={{ fontSize: '11px' }}> R$ {data.value.toFixed(2)}</Text>
        </Col>
    );

    const renderListItem = (item) => (
        <List.Item>
            <List.Item.Meta
                title={<Text strong>Nº Doc: {item.numero_doc}</Text>}
                description={
                    <>
                        <Text>Valor: R$ {parseFloat(item.valor).toFixed(2)}</Text><br />
                        <Text>Vencimento: {item.data_vencimento}</Text><br />
                        <Text>Situação: {renderSituacaoTag(item.situacao)}</Text>
                    </>
                }
            />
        </List.Item>
    );

    const renderSituacaoTag = (situacao) => {
        const colors = { pago: "green", aberto: "orange", parcial: "blue", atrasado: "red" };
        return <Tag color={colors[situacao] || "default"}>{situacao}</Tag>;
    };

    return (
        <>
            {bills === null ? (
                <Skeleton />
            ) : (
                <>
                    <Row gutter={16} style={{ marginBottom: 16 }}>
                        {renderStatistic("Pago", summary.pago, "green", <CheckCircleOutlined style={{ color: 'green' }} />)}
                        {renderStatistic("Aberto", summary.aberto, "orange", <ExclamationCircleOutlined style={{ color: 'orange' }} />)}
                        {renderStatistic("Parcial", summary.parcial, "blue", <ExclamationCircleOutlined style={{ color: 'blue' }} />)}
                        {renderStatistic("Atrasado", summary.atrasado, "red", <ExclamationCircleOutlined style={{ color: 'red' }} />)}
                    </Row>
                    <List
                        itemLayout="horizontal"
                        dataSource={bills}
                        renderItem={renderListItem}
                    />
                </>
            )}
        </>
    );
};

export default BuyerListBill;
