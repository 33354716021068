import React, { useState, useEffect } from 'react';
import {
  Radio,
  Divider,
  Space,
  Alert,
  Row,
  Col,
  Typography,
  Card,
  Form,
  Input,
  Skeleton,
  Select,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Cards from 'react-credit-cards-2';
import { initMercadoPago, CardPayment } from '@mercadopago/sdk-react';
import { openDB } from 'idb';
import { axiosClient } from '../../apiClient';

const { Paragraph } = Typography;
const { Option } = Select;

export default function BuyerListPayment(props) {
  const dispatch = useDispatch();
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [radioSelected, setRadioSelected] = useState(false);

  const [paymentSelected, setPaymentSelected] = useState(false);
  const [installments, setInstallments] = useState(1);
  const [cardInstallmentOptions, setCardInstallmentOptions] = useState([]);

  const [mercadopagoPublicKey, setMercadoPagoPublicKey] = useState(null);
  const [mercadopagoInit, setMercadopagoInit] = useState(false);
  const [mercadopago, setMercadopago] = useState(false);
  const [paymentConditions, setPaymentConditions] = useState([]);
  const [filteredConditions, setFilteredConditions] = useState([]);
  const [selectedCondition, setSelectedCondition] = useState(null);

  const [buyerData, setBuyerData] = useState({});
  const [updatePayments, setUpdatePayments] = useState(0);

  const buyerView = useSelector((state) => state.buyerView.buyerView);
  const logged = useSelector((state) => state.authentication.logged);
  const [loading, setLoading] = useState(true);
  const [cardData, setCardData] = useState({
    cvc: '',
    expiry: '',
    focused: 'name',
    name: '',
    number: '',
  });

  const shippingMethod = useSelector((state) => state.order.shippingMethod);
  const paymentMethod = useSelector((state) => state.order.paymentMethod);
  const cartProducts = useSelector((state) => state.cart.products);
  const cartReturned = useSelector((state) => state.cart.returned);
  const onlineStatus = useSelector((state) => state.sync.onlineStatus);
  const syncStatus = useSelector((state) => state.sync.syncStatus);
  const cartTotal = useSelector((state) => state.cart.cartTotal);
  const orderRebuy = useSelector((state) => state.orderRebuy);


  useEffect(() => {
    if (logged === 'seller' || logged === 'sellerUser') {
      axiosClient.get('/payment/conditions', { withCredentials: true })
        .then(response => {
          if (response.data.status === "Success") {
            setPaymentConditions(response.data.conditions);
          }
        })
        .catch(error => {
          console.error("Erro ao carregar as condições de pagamento:", error);
        });
    }
  }, [logged]);

  useEffect(() => {
    const conditionsForInstallment = paymentConditions.filter(condition =>
      condition.nQtdeParc === installments
    );
    setFilteredConditions(conditionsForInstallment);

    // Seleciona automaticamente a primeira condição, se existir
    if (conditionsForInstallment.length > 0) {
      setSelectedCondition(conditionsForInstallment[0].cCodigo);
    } else {
      setSelectedCondition(null);
    }
  }, [installments, paymentConditions]);

  async function getSingleData(dBStoreName, idx) {
    const db = await openDB('nupedido', 1);
    const value = await db.get(dBStoreName, idx);
    return value;
  }

  useEffect(() => {
    if (
      logged === 'buyer'
      || ((logged === 'seller' || logged === 'sellerUser') && buyerView)
    ) {
      axiosClient
        .post(
          '/buyer-info',
          { buyerView },
          { withCredentials: true },
        )
        .then((response) => {
          setBuyerData(response.data);
        });
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    setPaymentSelected(false);
    setMercadopagoInit(false);
    setMercadopago(false);
    dispatch({
      type: 'ADD_PAYMENT',
      paymentMethod: null,
    });
    setUpdatePayments(updatePayments + 1);
  }, [cartProducts, shippingMethod]);

  // MERCADOPAGO SETUP
  useEffect(() => {
    axiosClient
      .get('/integrations/mercadopago/get-public-key', { withCredentials: true })
      .then((response) => {
        response.data.status == 'Success'
          && setMercadoPagoPublicKey(response.data.public_key);
      });
  }, []);

  useEffect(() => {
    if (paymentSelected) {
      if (mercadopagoPublicKey && paymentSelected.metodo === 'mercadopago_credit_card' && mercadopagoInit === false) {
        initMercadoPago(mercadopagoPublicKey);
        setMercadopagoInit(0);
      }
    }
  }, [mercadopagoPublicKey, paymentSelected]);

  useEffect(() => {
    if (mercadopagoInit === 0) {
      setMercadopagoInit(mercadopagoInit + 1);
      let customer;
      buyerView ? customer = buyerView : customer = buyerData;
      const initialization = {
        amount: parseFloat(cartTotal).toFixed(2),
        payer: {
          email: customer.email,
          identification: {
            type: customer.tipo_pessoa === 'pf' ? 'CPF' : 'CNPJ',
            number: customer.tipo_pessoa === 'pf' ? customer.cpf : customer.cnpj,
          },
        },
      };
      const customization = {
        paymentMethods: {
          minInstallments: 1,
          maxInstallments: paymentSelected.aditional_features.max_installments,
          types: {
            excluded: ['debit_card'],
          },
        },
        cardholderName: {
          label: '',
          placeholder: 'Nome impresso no cartão',
        },
        visual: {
          style: {
            theme: 'light',
          },
          texts: {
            formSubmit: 'Finalizar pedido',
            formTitle: 'Cartão de crédito',
          },
        },
      };
      setMercadopago(<CardPayment
        initialization={initialization}
        customization={customization}
        onReady={() => { }}
        onSubmit={async (param) => {
          props.sendOrderFunc(param);
        }}
      />);
    }
  }, [mercadopagoInit]);

  useEffect(() => {
    if (paymentSelected.metodo === 'pagseguro_credit_card' || paymentSelected.metodo === 'mercadopago_credit_card') {
      renderInstallmentsOptions();
    }
  }, [cartTotal]);

  useEffect(() => {
    if (paymentSelected) {
      if (paymentSelected.metodo === 'mercadopago_credit_card') {
        props.setUseFinalizarPedidoButton(false);
      } else {
        props.setUseFinalizarPedidoButton(true);
      }
    }
  }, [paymentSelected]);

  useEffect(() => {
    if ((cartReturned || orderRebuy) && (buyerView || logged == 'buyer')) {
      setRadioSelected(false);
      if (onlineStatus) {
        axiosClient
          .post(
            '/payment/buyer/get',
            { buyerId: buyerView ? buyerView.id : null },
            { withCredentials: true },
          )
          .then((response) => {
            setPaymentOptions(response.data.payments);
            setLoading(false);
          });
      } else if (syncStatus === 'synced') {
        getSingleData('config', 4).then((data) => {
          setPaymentOptions(data.payments);
          setLoading(false);
        });
      }
    }
  }, [buyerView, cartReturned, updatePayments]);

  function amount(item) {
    return item.product_price_sale
      ? parseFloat(item.product_price_sale * item.quantity).toFixed(2)
      : parseFloat(item.product_price * item.quantity).toFixed(2);
  }

  function sum(prev, next) {
    return parseFloat(prev) + parseFloat(next);
  }

  function renderInstallmentsOptions() {
    const options = [];
    for (
      let i = 1;
      i <= parseInt(paymentSelected.aditional_features.max_installments);
      i++
    ) {
      const installment = getInstallmentValue(i);
      options.push(
        <Option value={i} key={i}>
          {installment[1]}
        </Option>,
      );
    }
    setCardInstallmentOptions(options);
    installmentsSelected(1);
  }

  function installmentsSelected(e) {
    const installment = getInstallmentValue(parseInt(e));
    dispatch({
      type: 'ADD_PAYMENT',
      paymentMethod: {
        ...paymentMethod,
        installments_selected: parseInt(e),
        total_value: parseFloat(
          parseFloat(installment[0]) * parseInt(e),
        ).toFixed(2),
        total_value_installment: parseFloat(installment[0]).toFixed(2),
      },
    });
  }

  function getInstallmentValue(i) {
    const free_installments = parseInt(
      paymentSelected.aditional_features.tax_free_installments,
    );
    const tax = parseFloat(paymentSelected.aditional_features.tax_per_installments) / 100;
    if (i <= free_installments) {
      var installment = parseFloat(cartTotal / i);
      var text = `${i}x R$ ${installment.toFixed(2)} (sem juros)`;
    } else {
      var installment = parseFloat(
        (cartTotal * (1 + tax) ** (i - free_installments)) / i,
      );
      var text = `${i}x R$ ${installment.toFixed(2)}`;
    }
    return [installment, text];
  }

  const onChange = (e) => {
    setRadioSelected(e.target.value);
    setPaymentSelected(paymentOptions[e.target.value]);
    dispatch({
      type: 'ADD_PAYMENT',
      paymentMethod: paymentOptions[e.target.value],
    });
  };

  function cc_format(value) {
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const matches = v.match(/\d{4,16}/g);
    const match = (matches && matches[0]) || '';
    const parts = [];
    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }
    if (parts.length) {
      return parts.join(' ');
    }
    return value;
  }

  function correctName(name) {
    const prefix = 'Por favor, insira o ';
    switch (name) {
      case 'cardName':
        return `${prefix}Nome no cartão`;
      case 'cardNumber':
        return `${prefix}Número do cartão`;
      case 'securityCode':
        return `${prefix}Código de segurança`;

      default:
        return 'Campo Obrigatório';
    }
  }

  const InstallmentsForm = ({ paymentSelected, valorTotal }) => {
    if (!paymentSelected?.aditional_features?.apply_installments || !paymentSelected.aditional_features.min_installment_value) return <></>;
    return (
      <Card size="small">
        <Form.Item label="Número de Parcelas" initialValue={1} name="installments" required>
          <Select
            value={installments}
            onChange={setInstallments}
          >
            {Array.from({ length: paymentSelected.aditional_features.max_payment_installments }).map((_, idx) => {
              const installmentAmount = (valorTotal / (idx + 1)).toFixed(2);

              if (parseFloat(installmentAmount) >= paymentSelected.aditional_features.min_installment_value) {
                return (
                  <Select.Option key={idx + 1} value={idx + 1}>
                    {idx + 1} Parcela(s) - R$ {installmentAmount.replace('.', ',')}
                  </Select.Option>
                );
              }

              return null;
            })}
          </Select>
        </Form.Item>

        {filteredConditions.length > 0 && (
          <Form.Item label="Condição de Pagamento" name="paymentCondition" initialValue={selectedCondition}>
            <Select
              showSearch
              placeholder="Selecione uma condição de pagamento"
              optionFilterProp="children"
              value={selectedCondition}
              onChange={value => setSelectedCondition(value)}
            >
              {filteredConditions.map((condition, index) => (
                <Select.Option key={condition.cCodigo} value={condition.cCodigo}>
                  {condition.cDescricao}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Card>
    );
  };



  return (
    <>
      <Divider orientation="left">Métodos de Pagamento</Divider>
      {loading ? (
        <Skeleton.Input style={{ width: 300 }} active />
      ) : (
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <Form
            form={props.form}
            scrollToFirstError
            name="nest-messages"
            validateMessages={{
              required: (name) => correctName(name),
            }}
          >
            <Row justify="start">
              <Col span={24}>
                {!loading && (
                  <Form.Item name="paymentMethod">
                    <Card size="small">
                      <Radio.Group onChange={onChange} value={radioSelected}>
                        <Space direction="vertical">
                          {!loading && paymentOptions && paymentOptions.map((item, i) => (
                            <Radio key={item.id} value={i}>
                              {item.descricao}
                            </Radio>
                          ))}

                          {!loading && paymentOptions.length === 0 && (
                            <Alert
                              banner
                              message="Nenhum método de pagamento disponível."
                              type="warning"
                            />
                          )}
                        </Space>
                      </Radio.Group>
                    </Card>
                  </Form.Item>
                )}
              </Col>
            </Row>
            {(logged === 'seller' || logged === 'sellerUser') && <InstallmentsForm paymentSelected={paymentSelected} valorTotal={cartTotal} />}
            {(paymentSelected.metodo === 'pagseguro_credit_card') && (
              <Row>
                <Col span={24}>
                  <Card title="Cartão de Crédito" size="small">
                    <Cards
                      cvc={cardData.cvc}
                      expiry={cardData.expiry}
                      focused={cardData.focused}
                      name={cardData.name}
                      number={cardData.number}
                      placeholders={{ name: 'Nome no Cartão' }}
                    />
                    <br />

                    <Form.Item
                      name={['cardNumber']}
                      rules={[
                        {
                          type: 'string',
                          required: true,
                          max: 100,
                        },
                      ]}
                      onChange={(e) => {
                        const cardNumber = cc_format(
                          e.target.value.replace(/\D/g, ''),
                        );

                        props.form.setFieldsValue({
                          cardNumber,
                        });
                        setCardData({
                          ...cardData,
                          number: cardNumber,
                          focused: 'number',
                        });
                      }}
                    >
                      <Input
                        onFocus={() => {
                          setCardData({
                            ...cardData,
                            focused: 'number',
                          });
                        }}
                        placeholder="Número do Cartão"
                      />
                    </Form.Item>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          name={['cardName']}
                          rules={[
                            {
                              type: 'string',
                              required: true,
                              max: 22,
                            },
                          ]}
                          onChange={(e) => {
                            const cardName = e.target.value
                              .replace(/[^A-Za-z ]+/g, '')
                              .substring(0, 22);
                            props.form.setFieldsValue({
                              cardName,
                            });
                            setCardData({
                              ...cardData,
                              name: cardName,
                              focused: 'name',
                            });
                          }}
                        >
                          <Input
                            onFocus={() => {
                              setCardData({
                                ...cardData,
                                focused: 'name',
                              });
                            }}
                            placeholder="Nome no Cartão"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={['numberinstallments']}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Select
                            style={{ width: '100%' }}
                            onChange={installmentsSelected}
                            placeholder="Parcelas"
                          >
                            {cardInstallmentOptions}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          name={['cardExpiry']}
                          initialValue=""
                          onChange={(e) => {
                            const expiry = e.target.value
                              .replace(/\D/g, '')
                              .substring(0, 4);
                            const month = expiry.substring(0, 2);
                            const year = expiry.substring(2, 4);
                            const today = new Date();

                            props.form.setFieldsValue({
                              cardExpiry: year ? `${month}/${year}` : month,
                            });

                            if (month.length === 2 && parseInt(month) > 12) {
                              props.form.setFields([
                                {
                                  name: 'cardExpiry',
                                  value: year ? `${month}/${year}` : month,
                                  errors: ['Mês incorreto'],
                                },
                              ]);
                            } else {
                              props.form.setFields([
                                {
                                  name: 'cardExpiry',
                                  value: year ? `${month}/${year}` : month,
                                  errors: [],
                                },
                              ]);

                              if (year.length === 2) {
                                const expireDate = new Date(
                                  `20${year}-${month}`,
                                );
                                expireDate.setMonth(
                                  expireDate.getMonth() + 1,
                                );
                                !(today < expireDate)
                                  && props.form.setFields([
                                    {
                                      name: 'cardExpiry',
                                      value: year
                                        ? `${month}/${year}`
                                        : month,
                                      errors: ['Cartão vencido'],
                                    },
                                  ]);
                              }
                            }

                            setCardData({
                              ...cardData,
                              expiry,
                              focused: 'expiry',
                            });
                          }}
                        >
                          <Input
                            onFocus={() => {
                              setCardData({
                                ...cardData,
                                focused: 'expiry',
                              });
                            }}
                            placeholder="Validade"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={['securityCode']}
                          rules={[
                            {
                              type: 'string',
                              required: true,
                              max: 4,
                            },
                          ]}
                          onChange={(e) => {
                            const cvc = e.target.value
                              .replace(/\D/g, '')
                              .substring(0, 4);

                            props.form.setFieldsValue({
                              securityCode: cvc,
                            });
                            setCardData({
                              ...cardData,
                              cvc,
                              focused: 'cvc',
                            });
                          }}
                        >
                          <Input
                            onFocus={() => {
                              setCardData({
                                ...cardData,
                                focused: 'cvc',
                              });
                            }}
                            placeholder="Código de Segurança"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            )}
            {(paymentSelected.metodo === 'mercadopago_credit_card') && (
              (mercadopago === false)
                ? <Skeleton />
                : mercadopago
            )}
            {paymentSelected.instructions && (
              <Row>
                <Col span={24}>
                  <Card title="Instruções" size="small">
                    <Paragraph style={{ whiteSpace: 'pre-wrap' }}>
                      {paymentSelected.instructions}
                    </Paragraph>
                  </Card>
                </Col>
              </Row>
            )}
          </Form>
        </Space>
      )}
    </>
  );
}
