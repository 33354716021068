function initialState() {
  return {
    page: 1,
    perPage: 20,
    totalPages: 1,
    column: null,
    order: null,
    pageUpdated: true,
    updating: false,
  };
}

export function pagination(state = initialState(), action) {
  switch (action.type) {
    case 'NEXT_PAGE':
      if (state.page + 1 <= state.totalPages) {
        return { ...state, page: state.page + 1, pageUpdated: false };
      }
      return state;

    case 'PREVIOUS_PAGE':
      if (state.page - 1 >= 1) {
        return { ...state, page: state.page - 1, pageUpdated: false };
      }
      return state;

    case 'SET_PAGE':
      return {
        ...state,
        totalPages: action.totalPages ? action.totalPages : state.totalPages,
        page: action.actualPage,
        pageUpdated: action.pageUpdated,
        column: action.column ? action.column : state.column,
        order: action.order ? action.order : state.order,
      };
    case 'SET_PER_PAGE':
      return {
        ...state,
        perPage: action.perPage,
        pageUpdated: action.pageUpdated,
        column: action.column ? action.column : state.column,
        order: action.order ? action.order : state.order,
      };

    default:
      return state;
  }
}
