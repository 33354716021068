import React, { useEffect, useState } from 'react';
import {
  Drawer, Result, Layout, Breadcrumb, Radio, Flex
} from 'antd';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styles from './catalogo.module.css';
import Table from '../../components/BuyerListProduct';
import Cart from '../../components/Cart';
import BuyerListOrder from '../../components/BuyerListOrder';
import BuyerListBill from '../../components/BuyerListBill';
import BuyerListInvoice from '../../components/BuyerListInvoice';
import CartButton from '../../components/CartButton';
import SearchProduct from '../../components/Search';
import OurDropdown from '../../components/Dropdown';
import Navbar from '../../components/Navbar';
import OrderEditMessage from '../../components/SellerEditOrderView';
import SellerBuyerView from '../../components/SellerBuyerView';
import PagesFooter from '../../components/PagesFooter';

const { Content } = Layout;

export default function Catalogo() {
  const dispatch = useDispatch();
  const [visibleCart, setVisibleCart] = useState(false);
  const [visibleOrder, setVisibleOrder] = useState(false);
  const [selectedOption, setSelectedOption] = useState('order');
  const logged = useSelector((state) => state.authentication.logged);
  const onlineStatus = useSelector((state) => state.sync.onlineStatus);
  const syncStatus = useSelector((state) => state.sync.syncStatus);

  const { orderRebuyId } = useParams();

  useEffect(() => {
    orderRebuyId && dispatch({ type: 'SET_ORDER_REBUY', orderRebuyId });
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (params.has('meus-pedidos')) {
      setSelectedOption('order');
      setVisibleOrder(true);
    }

    if (params.has('minhas-contas')) {
      setSelectedOption('bill');
      setVisibleOrder(true);
    }
  }, []);

  const options = [
    {
      label: 'Orçamentos',
      value: 'quote',
    },
    {
      label: 'Pedidos',
      value: 'order',
    },
    {
      label: 'Contas',
      value: 'bill',
    },
    {
      label: 'Notas',
      value: 'invoice',
    },
  ];

  return (
    (
      <Layout style={{
        position: 'absolute', width: '100%', left: '0px', top: '0px', marginTop: '55px', padding: '0 5px', paddingBottom: '70px',
      }}
      >
        {(logged === 'seller' || logged === 'sellerUser') && (orderRebuyId ? <OrderEditMessage /> : <SellerBuyerView />)}
        <Navbar handleToggle={() => setVisibleOrder(true)} />
        {!onlineStatus && syncStatus != 'synced' && (
          <Result
            style={{ marginTop: '100px' }}
            status="warning"
            title="Você está sem internet e seu aplicativo não está sincronizado"
            subTitle="Conecte seu aparelho na internet e sincronize os dados para usá-lo offline."
          />
        )}
        {(onlineStatus || (syncStatus === 'synced'))

          && (
            <Content>
              <Breadcrumb style={{ margin: '20px' }}>
                <Breadcrumb.Item href="">
                  <HomeOutlined />
                </Breadcrumb.Item>
                <Breadcrumb.Item href="">
                  <UserOutlined />
                  <span>Formulário de Pedidos</span>
                </Breadcrumb.Item>
              </Breadcrumb>
              <div className={styles['site-layout-content']}>
                <Drawer
                  title="Seu Pedido"
                  placement="right"
                  width={window.innerWidth > 375 ? 375 : '100%'}
                  onClose={() => setVisibleCart(false)}
                  open={visibleCart}
                >
                  <Cart />
                </Drawer>
                <Drawer
                  title="Histórico do Cliente"
                  placement="right"
                  width={window.innerWidth > 375 ? 375 : '100%'}
                  onClose={() => setVisibleOrder(false)}
                  open={visibleOrder}
                >
                  <Flex vertical gap="middle">
                    <Radio.Group
                      block
                      options={options}
                      value={selectedOption}
                      onChange={(e) => setSelectedOption(e.target.value)}
                      optionType="button"
                      buttonStyle="solid"
                    />
                  </Flex>
                  {['order', 'quote'].includes(selectedOption) && <BuyerListOrder orderType={selectedOption} />}
                  {selectedOption === 'bill' && <BuyerListBill />}
                  {selectedOption === 'invoice' && <BuyerListInvoice />}
                </Drawer>
                <div className={styles.filters}>
                  <SearchProduct />
                  <OurDropdown />
                </div>
                <Table />
                <CartButton handleToggle={() => setVisibleCart(!visibleCart)} />
              </div>
            </Content>
          )}
        <PagesFooter />
      </Layout>
    )
  );
}
