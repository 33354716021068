import React from 'react';
import { useSelector } from 'react-redux';
import styles from './cart_button.module.css';

export default function CartButton(props) {
  const cartProducts = useSelector((state) => state.cart.products);
  return (
    <div onClick={props.handleToggle} className={styles['dd-m-whatsapp']}>
      <span className={styles.icon} />
      <div className={styles['badge-wrapper']}>
        <div className={styles.badge}>{cartProducts.length}</div>
      </div>
    </div>
  );
}
