import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Menu,
  Dropdown,
  Skeleton,
  Col,
  Divider,
  Tabs,
} from 'antd';
// import styles from "./cadastro.module.css";
import { DownOutlined } from '@ant-design/icons';
import { axiosClient } from '../../apiClient';

const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;

export default function SellerCreateShipping() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [shippingOptions, setShippingOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(false);
  const [sending, setSending] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);

  const { shippingId } = useParams();

  useEffect(() => {
    axiosClient
      .post(
        '/shipping/available',
        { shippingId: false },
        { withCredentials: true },
      )
      .then((response) => {
        setShippingOptions(response.data.metodos);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (shippingId) {
      axiosClient
        .post(
          '/shipping/get',
          { shippingId: shippingId },
          { withCredentials: true },
        )
        .then((response) => {
          form.setFieldsValue(response.data.shippings[0]);
          setSelectedOption(response.data.shippings[0].metodo);
        });
    }
  }, []);

  const layout = {
    layout: 'vertical',
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 24,
    },
  };

  function deleteMethod() {
    setDeleting(true);
    axiosClient
      .post(
        '/shipping/delete',
        { shippingId: shippingId },
        { withCredentials: true },
      )
      .then((response) => {
        setDeleting(false);
        navigate('/admin/entrega/lista');
        // dispatch({ type: "LIST_SHIPPING" });
      });
  }

  function handleChange(value) {
    setSelectedOption(value);
  }

  const onFinish = (values) => {
    setSending(true);
    const data = shippingId
      ? { ...values, shippingId: shippingId }
      : values;
    if (data.valor) data.valor = parseInt(data.valor.replace(/\D/g, '')) / 100;
    axiosClient
      .post('/shipping/create', data, { withCredentials: true })
      .then((response) => navigate('/admin/entrega/lista')); // dispatch({ type: "LIST_SHIPPING" }));
  };

  const menu = (
    <Menu onClick={() => deleteMethod()}>
      <Menu.Item key="1">Excluir Método</Menu.Item>
    </Menu>
  );

  const routes = [
    {
      path: 'index',
      breadcrumbName: 'Início',
    },
    {
      path: 'first',
      breadcrumbName: 'Métodos de Entrega',
    },
    {
      path: 'second',
      breadcrumbName: shippingId ? 'Editar Método' : 'Novo Método',
    },
  ];

  function updatePriceField(field, val) {
    form.setFields([
      {
        name: field,
        value: isNaN(val)
          ? 'R$ 0,00'
          : val.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
      },
    ]);
  }

  return (
    <>
      <PageHeader
        title={shippingId ? 'Editar Método' : 'Novo Método'}
        onBack={() => navigate('/admin/entrega/lista')}
        breadcrumb={{ routes }}
        extra={
          shippingId && (
            <Dropdown overlay={menu} trigger={['click']}>
              <Button type="primary" loading={deleting}>
                Ações
                {' '}
                <DownOutlined />
              </Button>
            </Dropdown>
          )
        }
      />
      {loading ? (
        <Skeleton active />
      ) : (
        <Tabs defaultActiveKey="1" onChange={() => { }}>
          <TabPane tab="Dados Gerais" key="1">
            <Form
              form={form}
              scrollToFirstError
              {...layout}
              name="nest-messages"
              onFinish={onFinish}
            >
              <Form.Item
                name={['metodo']}
                label="Método"
                rules={[
                  {
                    type: 'string',
                    required: true,
                    max: 100,
                  },
                ]}
              >
                <Select
                  // style={{ width: 120 }}
                  onChange={handleChange}
                >
                  {shippingOptions.map((item, i) => (
                    <Option value={item.codigo} key={item.codigo}>
                      {item.nome}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {selectedOption === 'personalizado' && (
                <>
                  <Form.Item
                    name={['nome']}
                    label="Descrição"
                    rules={[
                      {
                        type: 'string',
                        required: true,
                        max: 100,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name={['valor']}
                    label="Valor"
                    onChange={(e) => {
                      const val = parseInt(e.target.value.replace(/\D/g, '')) / 100;
                      updatePriceField('valor', val);
                    }}
                    rules={[
                      {
                        type: 'string',
                        required: true,
                        max: 100,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </>
              )}
              <Divider orientation="left">Instruções</Divider>
              <Form.Item name="instructions">
                <TextArea rows={4} maxLength={1000} />
              </Form.Item>
              <Form.Item>
                <div style={{ marginTop: 30 }}>
                  <Row justify="center" gutter={[30, 12]}>
                    <Col xs={18} sm={8}>
                      <Button
                        block
                        onClick={() => {
                          navigate('/admin/entrega/lista');
                          // dispatch({ type: "LIST_SHIPPING" });
                        }}
                      >
                        Cancelar
                      </Button>
                    </Col>
                    <Col xs={18} sm={8}>
                      <Button
                        block
                        type="primary"
                        htmlType="submit"
                        loading={sending}
                      >
                        {shippingId ? 'Atualizar' : 'Cadastrar'}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
      )}
    </>
  );
}
