import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import styles from "./cadastro.module.css";
import {
  PlusOutlined,
  MinusCircleOutlined,
  DownOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Form,
  Input,
  Button,
  Select,
  Divider,
  Table,
  Row,
  Collapse,
  DatePicker,
  Tooltip,
  TreeSelect,
  Dropdown,
  Menu,
  Skeleton,
  Col,
  Typography,
} from 'antd';
import moment from 'moment';
import { axiosClient } from '../../apiClient';
import SearchCustomerRules from '../SearchCustomerRules';

const { Option, OptGroup } = Select;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Paragraph, Text } = Typography;
const { TextArea } = Input;

export default function SellerCreateProductRule() {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [products, setProducts] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [productAvailableTags, setProductAvailableTags] = useState([]);
  const [buyerAvailableTags, setBuyerAvailableTags] = useState([]);
  const [emQuemAplica, setEmQuemAplica] = useState({});
  const [aondeAplica, setAondeAplica] = useState({});
  const [action, setAction] = useState({});
  const [valueType, setValueType] = useState({});
  const [operador, setOperador] = useState('all');
  const [loading, setLoading] = useState(true);
  const [buyersFormated, setBuyersFormated] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [count, setCount] = useState(0);

  const { productRuleId } = useParams();

  useEffect(
    () => {
      axiosClient
        .get('/products/categories/list', { withCredentials: true })
        .then((response) => {
          setProductCategories(response.data);
        });
    },
    [],
  );

  useEffect(() => {
    axiosClient
      .get('/products/tag/list', { withCredentials: true })
      .then((out) => {
        setProductAvailableTags(out.data.tags);
      });
  }, []);

  useEffect(() => {
    if (productRuleId) {
      axiosClient
        .post(
          '/rules/product/get',
          { ruleId: productRuleId },
          { withCredentials: true },
        )
        .then((response) => {
          response.data.configuration?.progressiveTable && setDataSource(response.data.configuration.progressiveTable);
          let ceps_data = response.data.ceps_data
          let ceps_start = '';
          if (ceps_data && ceps_data.ceps_start.length > 0) {
            ceps_start = `${ceps_data.ceps_start.join('*\r\n')}*`;
          }
          let ceps_full = '';
          if (ceps_data && ceps_data.ceps_full.length > 0) {
            ceps_full = ceps_data.ceps_full.join('\r\n');
          }
          let ceps_range = '';
          if (ceps_data && ceps_data.ceps_range.length > 0) {
            ceps_range = ceps_data.ceps_range
              .map((item) => item.join('...'))
              .join('\r\n');
          }

          let buyerConditions = [];

          if (ceps_full || ceps_start || ceps_range) {
            buyerConditions = [
              ...buyerConditions,
              {
                emQuemAplica: 'buyersCep',
                ceps: [ceps_full, ceps_start, ceps_range]
                  .filter(Boolean)
                  .join('\r\n'),
              },
            ];
          }

          if (response.data.all_buyers) {
            buyerConditions = [
              ...buyerConditions,
              { emQuemAplica: 'allBuyers' },
            ];
          }

          if (response.data.buyers.length > 0) {
            const buyers_formated = response.data.buyers.map((option) => formatBuyer(option));
            setBuyersFormated(buyers_formated);
            buyerConditions = [
              ...buyerConditions,
              { emQuemAplica: 'specificBuyers', buyers: buyers_formated },
            ];
          }

          if (response.data.buyers_tags.length > 0) {
            buyerConditions = [
              ...buyerConditions,
              { emQuemAplica: 'buyersTag', tags: response.data.buyers_tags },
            ];
          }

          form.setFieldsValue({
            emQuemAplica: buyerConditions,
          });

          let updateFields = {};
          buyerConditions.map((value, index) => {
            updateFields[index] = value.emQuemAplica;
          });
          setEmQuemAplica(updateFields);

          let productConditions = [];

          if (response.data.all_products) {
            productConditions = [
              ...productConditions,
              { aondeAplica: 'allProducts' },
            ];
          }

          if (response.data.products.length > 0) {
            const products_formated = response.data.products.map((option) => formatProduct(option));
            productConditions = [
              ...productConditions,
              { aondeAplica: 'specificProducts', products: products_formated },
            ];
          }

          if (response.data.products_tags.length > 0) {
            productConditions = [
              ...productConditions,
              { aondeAplica: 'productTags', tags: response.data.products_tags },
            ];
          }

          if (response.data.products_categories.length > 0) {
            const categoriesIds = response.data.products_categories.map(
              (category) => category.id,
            );
            productConditions = [
              ...productConditions,
              { aondeAplica: 'productCategories', categories: categoriesIds },
            ];
          }

          form.setFieldsValue({
            aondeAplica: productConditions,
          });

          updateFields = {};
          productConditions.map((value, index) => {
            updateFields[index] = value.aondeAplica;
          });
          setAondeAplica(updateFields);

          response.data.start_date
            && response.data.end_date
            && form.setFieldsValue({
              date: [
                moment(response.data.start_date),
                moment(response.data.end_date),
              ],
            });

          form.setFieldsValue(response.data);
          setAction(response.data.action);
          setOperador(response.data.buyer_operator);
          setValueType(response.data.calculation_type);

          if (response.data.calculation_type == 'absolut') {
            const val = response.data.value;
            form.setFields([
              {
                name: 'value',
                value: isNaN(val)
                  ? 'R$ 0,00'
                  : val.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }),
              },
            ]);
          }
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    axiosClient
      .get('/customer/tag/list', { withCredentials: true })
      .then((out) => {
        setBuyerAvailableTags(out.data.tags);
      });
  }, []);

  useEffect(() => {
    axiosClient
      .post('/products/search', {
        search: '',
        page: '',
        perPage: -1,
      }, { withCredentials: true }).then((out) => setProducts(out.data.products));
  }, []);

  const layout = {
    layout: 'vertical',
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const formatBuyer = (option) => {
    let formated = '';
    if (option.tipo_pessoa === 'pf') {
      formated = `${option.id}-${option.email} ${(option.nome_fantasia || option.nome)} ${option.cpf}`;
    } else {
      formated = `${option.id
        } - ${option.email
        } - ${option.razao_social
        } ${option.nome_fantasia
        } ${option.cnpj}`;
    }
    return formated;
  };

  const handleSelectChange = (values) => {
    setBuyersFormated(values);
    form.setFieldsValue({
      emQuemAplica: [{ emQuemAplica: 'specificBuyers', buyers: values }],
    });
  };

  const formatProduct = (option) => {
    const formated = `${option.id
      } - ${option.product_sku
      } ${option.product_name
      } ${option.gtin}`;
    return formated;
  };

  const onFinish = (values) => {

    let data = productRuleId
      ? { ...values, ruleId: productRuleId }
      : values;
    data = { ...data, buyerConditionType: operador };

    if (values.action === 'progressiveDiscount') {

      if (dataSource.length === 0 ||
        dataSource.some(
          (item) =>
            !(Number(item.quantidade) > 0) || !(Number(item.porcentagem) > 0)
        )) {
        form.setFields([
          {
            name: 'progressiveTable',
            errors: ['Adicione ao menos uma linha com valores.'],
          },
        ]);
        return;
      } else {
        data = { ...data, buyerConditionType: operador, progressiveTable: dataSource };
      }
    }

    if (!values.emQuemAplica || values.emQuemAplica.length === 0) {
      form.setFieldsValue({
        emQuemAplica: [{ emQuemAplica: [] }],
      });
      form.setFields([
        {
          name: 'emQuemAplica',
          errors: ['É necessário adicionar pelo menos uma condição.'],
        },
      ]);
      return;
    }
    if (!values.aondeAplica || values.aondeAplica.length === 0) {
      form.setFieldsValue({
        aondeAplica: [{ aondeAplica: [] }],
      });
      form.setFields([
        {
          name: 'aondeAplica',
          errors: ['É necessário adicionar pelo menos uma condição.'],
        },
      ]);
      return;
    }

    axiosClient
      .post('/rules/product/create', data, { withCredentials: true })
      .then((response) => navigate('/admin/catalogo/regras-produto'));// dispatch({ type: "LIST_PRODUCT_RULE" }));
  };

  const onEmQuemAplicaChange = (value, Formkey) => {
    const obj = { ...emQuemAplica };
    obj[Formkey] = value;
    setEmQuemAplica(obj);
  };

  const onAondeAplicaChange = (value, Formkey) => {
    const obj = { ...aondeAplica };
    obj[Formkey] = value;
    setAondeAplica(obj);
  };

  const onActionChange = (value) => {
    setAction(value);
  };
  const onOperadorChange = (value) => {
    setOperador(value);
  };

  function deleteRule() {
    axiosClient
      .post(
        '/rules/product/delete',
        { ruleId: productRuleId },
        { withCredentials: true },
      )
      .then((response) => navigate('/admin/catalogo/regras-produto')); // dispatch({ type: "LIST_PRODUCT_RULE" }));
  }

  const menu = (
    <Menu onClick={() => deleteRule()}>
      <Menu.Item key="1">Excluir Regra</Menu.Item>
    </Menu>
  );

  const handleAdd = () => {
    setDataSource([...dataSource, { key: dataSource.length, quantidade: '', porcentagem: '' }]);
    setCount(count + 1);
  };

  const handleInputChange = (key, dataIndex, value) => {
    if (/^\d*$/.test(value) && (!value || parseInt(value, 10) <= 1000)) {
      setDataSource((prev) =>
        prev.map((item) => (item.key === key ? { ...item, [dataIndex]: value } : item))
      );
    }
  };


  const handleDelete = (key) => {
    setDataSource(dataSource.filter(item => item.key !== key));
  };

  const columns = [
    {
      title: 'Quantidade',
      dataIndex: 'quantidade',
      render: (_, record) => (
        <Input
          value={record.quantidade}
          onChange={(e) => handleInputChange(record.key, 'quantidade', e.target.value)}
        />
      ),
    },
    {
      title: 'Porcentagem',
      dataIndex: 'porcentagem',
      render: (_, record) => (
        <Input
          value={record.porcentagem}
          onChange={(e) => handleInputChange(record.key, 'porcentagem', e.target.value)}
          addonAfter="%"
        />
      ),
    },
    {
      title: 'Ação',
      render: (_, record) => (
        <Button onClick={() => handleDelete(record.key)} type="link">
          Deletar
        </Button>
      ),
    },
  ];


  return (
    <>
      <PageHeader
        title={productRuleId ? 'Editar Regra' : 'Nova Regra'}
        onBack={() => navigate('/admin/catalogo/regras-produto')}
        extra={
          productRuleId && (
            <Dropdown overlay={menu} trigger={['click']}>
              <Button type="primary">
                Ações
                {' '}
                <DownOutlined />
              </Button>
            </Dropdown>
          )
        }
      />
      {loading ? (
        <Skeleton active />
      ) : (
        <Form
          form={form}
          scrollToFirstError
          {...layout}
          name="nest-messages"
          onFinish={onFinish}
        >
          <Divider orientation="left">Dados gerais</Divider>
          <Form.Item
            name={['name']}
            label="Nome da regra"
            rules={[
              {
                type: 'string',
                required: true,
                max: 100,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Divider orientation="left">
            <Tooltip
              placement="topLeft"
              title="Escolha para quais clientes a regra será aplicada"
            >
              A quem se aplica
            </Tooltip>
          </Divider>
          <Form.List name="emQuemAplica">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({
                  key, name, fieldKey, ...restField
                }) => (
                  <Collapse key={key}>
                    <Panel header={`Condição ${parseInt(key + 1)}`} key={key}>
                      <Form.Item
                        {...restField}
                        name={[name, 'emQuemAplica']}
                        fieldKey={[fieldKey, 'emQuemAplica']}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          placeholder="Selecione a condição"
                          onChange={(value) => onEmQuemAplicaChange(value, key)}
                          allowClear
                        >
                          <Option value="allBuyers">Todos clientes</Option>
                          <Option value="specificBuyers">
                            Clientes específicos
                          </Option>
                          <Option value="buyersTag">
                            Clientes que contém tag
                          </Option>
                          <Option value="buyersCep">
                            Clientes com CEP específico
                          </Option>
                        </Select>
                      </Form.Item>
                      {emQuemAplica[key] === 'buyersCep' && (
                        <>
                          <Text>
                            Defina um CEP por linha. CEPs que contenham curingas
                            (exemplo: 170*) ou intervalos numéricos (exemplo:
                            17000000...17099999) também são suportados.
                          </Text>
                          <Form.Item
                            {...restField}
                            name={[name, 'ceps']}
                            fieldKey={[fieldKey, 'ceps']}
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <TextArea rows={4} />
                          </Form.Item>
                        </>
                      )}
                      {emQuemAplica[key] === 'buyersTag' && (
                        <Form.Item
                          {...restField}
                          name={[name, 'tags']}
                          fieldKey={[fieldKey, 'tags']}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Select
                            placeholder="Selecione as tags"
                            mode="multiple"
                            allowClear
                          >
                            {buyerAvailableTags.map((group, i) => (
                              <OptGroup
                                key={`group_${group.id}`}
                                label={group.title}
                              >
                                {group.tags.map((tag, i) => (
                                  <Option
                                    key={`tag_${tag.id}`}
                                    value={`${group.id}_${tag.id}`}
                                  >
                                    {tag.title}
                                  </Option>
                                ))}
                              </OptGroup>
                            ))}
                          </Select>
                        </Form.Item>
                      )}
                      {emQuemAplica[key] === 'specificBuyers' && (
                        <Form.Item
                          {...restField}
                          name={[name, 'buyers']}
                          fieldKey={[fieldKey, 'buyers']}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <SearchCustomerRules
                            handleSelectChange={handleSelectChange}
                            buyersFormated={buyersFormated}
                          />
                        </Form.Item>
                      )}
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Panel>
                  </Collapse>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Adicionar Condição
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Divider orientation="left">
            <Tooltip
              placement="topLeft"
              title="Escolha em quais produtos a regra será aplicada"
            >
              Para quais produtos
            </Tooltip>
          </Divider>
          <Form.List name="aondeAplica">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({
                  key, name, fieldKey, ...restField
                }) => (
                  <Collapse key={key}>
                    <Panel header={`Condição ${parseInt(key + 1)}`} key={key}>
                      <Form.Item
                        {...restField}
                        name={[name, 'aondeAplica']}
                        fieldKey={[fieldKey, 'aondeAplica']}
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          placeholder="Selecione a condição"
                          onChange={(value) => onAondeAplicaChange(value, key)}
                          allowClear
                        >
                          <Option value="allProducts">Todos produtos</Option>
                          <Option value="specificProducts">
                            Produtos específicos
                          </Option>
                          <Option value="productTags">
                            Produtos que contém tag
                          </Option>
                          <Option value="productCategories">
                            Categoria de produtos
                          </Option>
                        </Select>
                      </Form.Item>
                      {aondeAplica[key] === 'specificProducts' && (
                        <Form.Item
                          {...restField}
                          name={[name, 'products']}
                          fieldKey={[fieldKey, 'products']}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Select
                            autoClearSearchValue={false}
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="Selecione os produtos"
                            allowClear
                          >
                            {products.map((option) => (
                              <Option
                                key={option.id}
                                value={formatProduct(option)}
                              >
                                {`${option.product_sku
                                  } - ${option.product_name}`}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      )}
                      {aondeAplica[key] === 'productTags' && (
                        <Form.Item
                          {...restField}
                          name={[name, 'tags']}
                          fieldKey={[fieldKey, 'tags']}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Select
                            placeholder="Selecione as tags"
                            mode="multiple"
                            allowClear
                          >
                            {productAvailableTags.map((group, i) => (
                              <OptGroup
                                key={`group_${group.id}`}
                                label={group.title}
                              >
                                {group.tags.map((tag, i) => (
                                  <Option
                                    key={`tag_${tag.id}`}
                                    value={`${group.id}_${tag.id}`}
                                  >
                                    {tag.title}
                                  </Option>
                                ))}
                              </OptGroup>
                            ))}
                          </Select>
                        </Form.Item>
                      )}
                      {aondeAplica[key] === 'productCategories' && (
                        <Form.Item
                          {...restField}
                          name={[name, 'categories']}
                          fieldKey={[fieldKey, 'categories']}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <TreeSelect
                            treeLine
                            multiple
                            style={{ width: '100%' }}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            treeData={productCategories}
                            placeholder="Selecione uma categoria"
                            treeDefaultExpandAll
                          />
                        </Form.Item>
                      )}
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Panel>
                  </Collapse>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Adicionar Condição
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Divider orientation="left">Ação</Divider>
          <Form.Item
            name="action"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder="Selecione a ação"
              onChange={onActionChange}
              allowClear
            >
              <OptGroup label="Preço">
                <Option value="discount">Desconto</Option>
                <Option value="progressiveDiscount">Desconto Progressivo</Option>
                <Option value="addition">Acréscimo</Option>
              </OptGroup>
              <OptGroup label="Exibição">
                <Option value="hide">Esconder</Option>
              </OptGroup>
            </Select>
          </Form.Item>
          {(action === 'progressiveDiscount') && (
            <Form.Item
              name="progressiveTable"
            >
              <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }}>
                Adicionar Linha
              </Button>
              <Table
                bordered
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                rowKey="key"
              />
            </Form.Item>
          )}
          {(action === 'discount' || action === 'addition') && (
            <>
              <Form.Item
                name="calculation_type"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder={
                    action == 'discount'
                      ? 'Tipo do desconto'
                      : 'Tipo do acréscimo'
                  }
                  onChange={(value) => {
                    form.setFields([
                      {
                        name: 'value',
                        value: '',
                      },
                    ]);
                    setValueType(value);
                  }}
                >
                  <Option value="percent">Porcentagem %</Option>
                  <Option value="absolut">Valor fixo R$</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="value"
                rules={[
                  {
                    required: true,
                  },
                ]}
                onChange={(e) => {
                  const val = e.target.value.replace(/\D/g, '');
                  if (valueType == 'percent') {
                    const val = parseInt(e.target.value.replace(/\D/g, ''));
                    form.setFields([
                      {
                        name: 'value',
                        value: isNaN(val) ? 0 : val,
                      },
                    ]);
                  }
                  if (valueType == 'absolut') {
                    const val = parseInt(e.target.value.replace(/\D/g, '')) / 100;
                    form.setFields([
                      {
                        name: 'value',
                        value: isNaN(val)
                          ? 'R$ 0,00'
                          : val.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          }),
                      },
                    ]);
                  }
                }}
              >
                <Input
                  placeholder={
                    action == 'discount'
                      ? 'Valor do desconto'
                      : 'Valor do acréscimo'
                  }
                  suffix={valueType == 'percent' && '%'}
                />
              </Form.Item>
            </>
          )}
          <Form.Item>
            <div style={{ marginTop: 30 }}>
              <Row justify="center" gutter={[30, 12]}>
                <Col xs={18} sm={8}>
                  <Button
                    block
                    onClick={() => {
                      navigate('/admin/catalogo/regras-produto');
                      // dispatch({ type: "LIST_PRODUCT_RULE" });
                    }}
                  >
                    Cancelar
                  </Button>
                </Col>
                <Col xs={18} sm={8}>
                  <Button block type="primary" htmlType="submit">
                    {productRuleId ? 'Atualizar' : 'Cadastrar'}
                  </Button>
                </Col>
              </Row>
            </div>
          </Form.Item>
        </Form>
      )}
    </>
  );
}
