function initialState() {
    return ({ progressiveDiscountRules: null });
}

export function rules(state = initialState(), action) {
    switch (action.type) {
        case 'SET_PROGRESSIVE_DISCOUNT_RULES':
            return { ...state, progressiveDiscountRules: action.progressiveDiscountRules };
        default:
            return state;
    }
}
