function initialState() {
  return ({ list: [] });
}

export function categoryList(state = initialState(), action) {
  switch (action.type) {
    case 'SET_LIST':
      return { ...state, list: action.list };
    default:
      return state;
  }
}
