import React, { useEffect, useState } from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Row,
  Col,
  Input,
  Button,
  Divider,
  Tooltip,
  Form,
  Select,
  Menu,
  Space,
  Dropdown,
  Switch,
  message,
  Skeleton,
  Checkbox,
  Tabs,
  Typography,
  Alert,
  InputNumber
} from 'antd';
import { useSelector } from 'react-redux';
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  KeyOutlined,
  CheckOutlined,
  LoadingOutlined,
  DownOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import PhoneInput from 'antd-phone-input';
import {
  mascaraPhone,
  mascaraCnpj,
  mascaraCpf,
  validaCpfCnpj,
} from '../../functions/validation';
import { axiosClient } from '../../apiClient';
import { useNavigate } from 'react-router-dom';


const CheckboxGroup = Checkbox.Group;
const { TabPane } = Tabs;
const { Option } = Select;
const { Text } = Typography;
const { TextArea } = Input;

export default function SellerCreateUser(props) {
  const navigate = useNavigate();
  const [changePass, setChangePass] = useState(true);
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [userType, setUserType] = useState('user');
  const editItems = useSelector((state) => state.editItems);
  const sellerData = useSelector((state) => state.sellerData);

  const [configValues, setConfigValues] = useState({ buyers_without_sellers: false, order_to_sellers: false });

  const [customersOptions, setCustomersOptions] = useState([
    'Cadastros',
    'Solicitações',
    'Tags',
  ]);
  const [customersValues, setCustomersValues] = useState([]);

  const [productsOptions, setProductsOptions] = useState([
    'Cadastros',
    'Categorias',
    'Tags',
  ]);
  const [productsValues, setProductsValues] = useState([]);

  const [ordersOptions, setOrdersOptions] = useState([
    'Finalizados',
    'Orçamentos',
    'Em Aberto',
  ]);
  const [ordersValues, setOrdersValues] = useState([]);

  const [rulesOptions, setRulesOptions] = useState([
    'Pagamento',
    'Entrega',
    'Catálogo',
  ]);
  const [rulesValues, setRulesValues] = useState([]);

  useEffect(() => {
    props.form.setFieldsValue({
      send_email: true,
    });
    if (editItems.userId) {
      setLoading(true);
      setChangePass(false);
      axiosClient
        .post(
          '/auth/get/seller-user',
          { userId: editItems.userId },
          { withCredentials: true },
        )
        .then((response) => {
          const responseData = response.data;

          let ceps_start = '';
          let ceps_full = '';
          let ceps_range = '';

          if (responseData.ceps) {
            if (responseData.ceps.ceps_start.length > 0) {
              ceps_start = `${responseData.ceps.ceps_start.join('*\r\n')}*`;
            }
            if (responseData.ceps.ceps_full.length > 0) {
              ceps_full = responseData.ceps.ceps_full.join('\r\n');
            }
            if (responseData.ceps.ceps_range.length > 0) {
              ceps_range = responseData.ceps.ceps_range
                .map((item) => item.join('...'))
                .join('\r\n');
            }
          }

          responseData.ceps = [ceps_full, ceps_start, ceps_range]
            .filter(Boolean)
            .join('\r\n');

          props.form.setFieldsValue(responseData);
          responseData.permissions.userType && setUserType(responseData.permissions.userType);
          'configuration' in responseData.permissions &&
            setConfigValues(responseData.permissions.configuration);
          setProductsValues(responseData.permissions.products);
          setCustomersValues(responseData.permissions.customers);
          setOrdersValues(responseData.permissions.orders);
          setRulesValues(responseData.permissions.rules);
          setLoading(false);
          responseData.cpf
            && props.form.setFieldsValue({
              cpf: mascaraCpf(responseData.cpf),
            });
        });
    }
  }, []);

  function deleteUser() {
    setDeleting(true);
    axiosClient
      .post(
        '/auth/delete/seller-user',
        { userId: editItems.userId },
        { withCredentials: true },
      )
      .then((response) => {
        if (response.data.status === 'Success') {
          message.success('Usuário deletado com sucesso', 4) && navigate('/admin');
        } else {
          message.error('Erro ao deletar usuário', 4);
        }
      });
  }

  const layout = {
    layout: 'vertical',
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const validarCPF = (e) => {
    const cpf = mascaraCpf(e.target.value);
    if (validaCpfCnpj(cpf) == false) {
      props.form.setFields([
        {
          name: 'cpf',
          errors: ['CPF Inválido'],
        },
      ]);
    }
  };

  const onFinish = (values) => {
    setSending(true);

    const permissions = {
      userType: userType,
      configuration: configValues,
      customers: customersValues,
      products: productsValues,
      orders: ordersValues,
      rules: rulesValues,
    };

    const data = editItems.userId
      ? { ...values, userId: editItems.userId, permissions }
      : { ...values, permissions };
    axiosClient
      .post('/auth/register/seller-user', data, { withCredentials: true })
      .then((response) => {
        if (response.data.status === 'Success') {
          message.success(
            editItems.userId
              ? 'Usuário atualizado com sucesso'
              : 'Usuário adicionado com sucesso',
            4,
          );
        } else if (response.data.msg === 'No credits available') {
          message.error('Você não possui créditos para completar essa requisição.', 4);
        } else {
          response.data.in_use.usename == true
            && props.form.setFields([
              {
                name: 'username',
                errors: ['Usuário já em uso'],
              },
            ]);
          response.data.in_use.phone == true
            && props.form.setFields([
              {
                name: 'whatsapp_backoffice',
                errors: ['Whatsapp já em uso'],
              },
            ]);
        }
        setSending(false);
      });
  };

  const menu = (
    <Menu onClick={() => deleteUser()}>
      <Menu.Item key="1">Excluir Usuário</Menu.Item>
    </Menu>
  );

  return (
    <>
      <PageHeader
        title={
          editItems.userId != false && editItems.userId != 'request'
            ? 'Editar Usuário'
            : 'Cadastrar Usuário'
        }
        extra={
          editItems.userId != false
          && editItems.userId != 'request' && (
            <Dropdown overlay={menu} trigger={['click']}>
              <Button type="primary">
                {deleting ? (
                  <>
                    <LoadingOutlined />
                    {' '}
                    Ações
                    <DownOutlined />
                  </>
                ) : (
                  <>
                    Ações
                    {' '}
                    <DownOutlined />
                  </>
                )}
              </Button>
            </Dropdown>
          )
        }
      />
      {loading ? (
        <Skeleton active />
      ) : (
        <Form
          form={props.form}
          scrollToFirstError
          {...layout}
          name="nest-messages"
          onFinish={onFinish}
        >
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name={['nome']}
                label="Nome"
                rules={[
                  {
                    type: 'string',
                    required: true,
                    max: 100,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name="user_role"
                label="Cargo"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select placeholder="Selecione o tipo de pessoa" allowClear>
                  <Option value="manager">Gestor</Option>
                  <Option value="seller">Vendedor</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={6}>
              <Form.Item
                name={['username']}
                label="Nome de usuário"
                rules={[
                  {
                    required: true,
                    message: 'Escolha um nome de usuário',
                  },
                  {
                    message: 'Máximo de 20 caracteres',
                    max: 20,
                  },
                ]}
              >
                <Input
                  style={{ textAlign: 'right' }}
                  addonAfter={`@${sellerData.username}`}
                  onChange={(e) => {
                    props.form.setFieldsValue({
                      username: e.target.value
                        .normalize('NFD')
                        .replace(/\p{Diacritic}/gu, '')
                        .replace(/[^A-Z0-9]+/gi, '_')
                        .toLowerCase(),
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={9}>
              <Form.Item
                name={['cpf']}
                label="CPF"
                rules={[
                  {
                    type: 'string',
                    max: 100,
                    // required: true,
                  },
                ]}
              >
                <Input
                  onBlur={validarCPF}
                  maxLength={14}
                  onChange={(e) => {
                    props.form.setFieldsValue({
                      cpf: mascaraCpf(e.target.value),
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={9}>
              <Form.Item
                name={['email']}
                label="Email"
                rules={[
                  {
                    type: 'email',
                    // required: true,
                    max: 100,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          {(props.userType === 'representante_whatsapp') && (
            <>
              <Divider orientation="left">Whatsapp Backoffice Inteligente</Divider>
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name={['whatsapp_backoffice']}
                    label="Celular (com DDD)"
                    rules={[
                      {
                        type: 'string',
                        required: props.userType === 'representante_whatsapp',
                        max: 100,
                      },
                    ]}
                  >
                    <PhoneInput
                      onChange={(value, e) => {
                        props.form.setFieldsValue({
                          whatsapp_backoffice: String(e.target.value),
                        });
                      }}
                      enableSearch
                    />

                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          <>
            <Divider orientation="left">Área de atuação</Divider>
            <Text>
              Defina um CEP por linha. CEPs que contenham curingas (exemplo:
              170*) ou intervalos numéricos (exemplo: 17000000...17099999)
              também são suportados.
            </Text>
            <Form.Item name="ceps">
              <TextArea rows={4} />
            </Form.Item>
          </>
          <Divider orientation="left">Senha</Divider>
          <Button
            type="link"
            style={{ display: changePass ? 'none' : 'block' }}
            onClick={() => setChangePass(true)}
          >
            Mudar senha
          </Button>
          {changePass && (
            <>
              <Space align="baseline">
                <Form.Item
                  name={['password']}
                  rules={[
                    {
                      type: 'string',
                      max: 100,
                    },
                  ]}
                >
                  <Input.Password
                    style={{ width: '200px' }}
                    placeholder="Senha"
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                </Form.Item>
                <Form.Item>
                  <Tooltip title="Gerar senha aleatória">
                    <Button
                      type="primary"
                      onClick={() => {
                        props.form.setFieldsValue({
                          password: Math.random().toString(36).slice(-6),
                        });
                      }}
                    >
                      <KeyOutlined />
                    </Button>
                  </Tooltip>
                </Form.Item>
              </Space>
              <Form.Item name={['send_email']}>
                Enviar senha por email?
                {' '}
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultChecked
                  onChange={(checked) => {
                    props.form.setFieldsValue({
                      send_email: checked,
                    });
                  }}
                />
              </Form.Item>
            </>
          )}
          <Divider orientation="left">Permissões</Divider>

          <Space direction="vertical" size="large">
            <Select
              value={userType}
              style={{
                width: 180,
              }}
              onChange={setUserType}
              options={[
                {
                  value: 'admin',
                  label: 'Administrador',
                },
                {
                  value: 'user',
                  label: 'Usuário',
                },

              ]}
            />
            {userType === 'admin' &&
              <Alert message="
O administrador pode gerenciar dados da empresa, controlar usuários e permissões, administrar a assinatura da plataforma e configurar integrações com outros sistemas." type="info" showIcon />
            }
            {userType === 'user' &&
              <>
                <Space direction="vertical">
                  <Checkbox checked={configValues.buyers_without_sellers} onChange={(values) => setConfigValues({ ...configValues, buyers_without_sellers: values.target.checked })}>
                    Acesso a clientes sem vendedor associado
                  </Checkbox>
                  <Checkbox checked={configValues.order_to_sellers} onChange={(values) => setConfigValues({ ...configValues, order_to_sellers: values.target.checked })}>
                    Finalizar pedido para outro vendedor
                  </Checkbox>
                  <Checkbox checked={configValues.modify_price_in_cart} onChange={(values) => setConfigValues({ ...configValues, modify_price_in_cart: values.target.checked })}>
                    Modificar o preço dos produtos no carrinho
                  </Checkbox>

                  <Space>
                    <Text>Desconto Máximo Permitido</Text>
                    <InputNumber
                      min={0}
                      max={100}
                      value={configValues.max_discount_percentage}
                      onChange={(value) => {
                        const newValue = Math.max(0, Math.min(100, value));
                        setConfigValues({ ...configValues, max_discount_percentage: newValue });
                      }}
                      style={{ width: '120px' }}
                      addonAfter="%"
                    />
                  </Space>
                </Space>
                <Tabs type="card" defaultActiveKey="1">
                  <TabPane tab="Clientes" key="customers">
                    <CheckboxGroup
                      options={customersOptions}
                      value={customersValues}
                      onChange={(values) => setCustomersValues(values)}
                    />
                  </TabPane>
                  <TabPane tab="Produtos" key="products">
                    <CheckboxGroup
                      options={productsOptions}
                      value={productsValues}
                      onChange={(values) => setProductsValues(values)}
                    />
                  </TabPane>
                  <TabPane tab="Pedidos" key="orders">
                    <CheckboxGroup
                      options={ordersOptions}
                      value={ordersValues}
                      onChange={(values) => setOrdersValues(values)}
                    />
                  </TabPane>
                  <TabPane tab="Regras" key="rules">
                    <CheckboxGroup
                      options={rulesOptions}
                      value={rulesValues}
                      onChange={(values) => setRulesValues(values)}
                    />
                  </TabPane>
                </Tabs>
              </>
            }
          </Space>
          <>
            <Form.Item>
              <div style={{ marginTop: 30 }}>
                <Row justify="center" gutter={[30, 12]}>
                  <Col xs={18} sm={6}>
                    <Button
                      block
                      onClick={() => {
                        navigate('/admin')
                      }}
                    >
                      Cancelar
                    </Button>
                  </Col>
                  <Col xs={18} sm={6}>
                    <Button
                      block
                      type="primary"
                      htmlType="submit"
                      loading={sending}
                    >
                      {editItems.userId ? 'Salvar' : 'Cadastrar'}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form.Item>
          </>
        </Form>
      )}
    </>
  );
}
