import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Table,
  Tag,
  Button,
  Skeleton,
  Empty,
  Dropdown,
  Menu,
  Drawer,
  Upload,
  Form,
  Select,
  Row,
  Col,
  message,
  Switch,
  Space,
  Progress,
  Typography,
} from 'antd';
import {
  DownOutlined,
  DeleteOutlined,
  UploadOutlined,
  ImportOutlined,
  TagOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
// import styles from "./cadastro.module.css";
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { axiosClient } from '../../apiClient';
import { mascaraCnpj, mascaraCpf } from '../../functions/validation';
import SearchCustomer from '../SearchCustomer';
import SellerListCustomerImportClientsSheet from '../SellerListCustomerImportClientsSheet';

const { Link } = Typography;

export default function SellerListCustomer() {
  const navigate = useNavigate();
  const { Option, OptGroup } = Select;
  const dispatch = useDispatch();
  const [tagForm] = Form.useForm();
  const [sellerUserForm] = Form.useForm();
  const [loadingButton, setLoadingButton] = useState(false);
  const [importClientsDrawerVisible, setImportClientsDrawerVisible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);
  const [availableSellerUsers, setAvailableSellerUsers] = useState([]);
  const [selectTags, setSelectTags] = useState(false);
  const [selectSellerUser, setSelectSellerUser] = useState(false);
  const [deletingBulkClients, setDeletingBulkClients] = useState(false);
  const buyerList = useSelector((state) => state.buyerList);
  const buyerPagination = useSelector((state) => state.buyerPagination);
  const searchTerm = useSelector((state) => state.searchState.searchTerm);

  useEffect(() => {
    axiosClient
      .get('/customer/tag/list', { withCredentials: true })
      .then((out) => {
        setAvailableTags(out.data.tags);
      });
  }, []);

  useEffect(() => {
    axiosClient
      .get('/auth/list/seller-users', { withCredentials: true })
      .then((out) => {
        setAvailableSellerUsers(out.data);
      });
  }, []);

  useEffect(() => {
    setTableData(buyerList.list.map((obj, index) => ({ ...obj, key: index })));
  }, [buyerList.list]);

  useEffect(() => {
    setSelectedRowKeys([]);
  }, [searchTerm]);

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  const [width, height] = useWindowSize();

  const smallColumns = [
    {
      title: 'Descrição',
      render: (record) => (
        <>
          {record.tipo_pessoa === 'pf' ? (
            <Link>{(record.nome_fantasia || record.nome)}</Link>
          ) : record.razao_social ? (
            <Link>{record.razao_social}</Link>
          ) : (
            <Link>{record.nome_fantasia}</Link>
          )}
          <br />
          {record.tipo_pessoa === 'pf'
            ? mascaraCpf(record.cpf)
            : mascaraCnpj(record.cnpj)}
          <br />
          {record.municipio && record.uf ? (
            <>
              {`${record.municipio} - ${record.uf}`}
              <br />
            </>
          ) : (
            ' '
          )}
          <>
            {record.selleruser ? (
              <Tag
                icon={<UserSwitchOutlined />}
                key={`sellerUser_${record.id}`}
                style={{ borderStyle: 'dashed' }}
              >
                {record.selleruser.nome}
              </Tag>
            ) : (
              <Tag
                icon={<UserSwitchOutlined />}
                key={`sellerUser_${record.id}`}
                style={{ borderStyle: 'dashed', color: 'red' }}
              >
                Sem Vendedor
              </Tag>
            )}
            {record.tags.map((tag) => (
              <Tag key={`tag_${tag.id}_${record.id}`}>
                {tag.title.toUpperCase()}
              </Tag>
            ))}
          </>
        </>
      ),
      ellipsis: true,
    },
  ];

  const bigColumns = [
    {
      title: 'Nome',
      dataIndex: 'nome',
      width: '25%',
      render: (text, record) => (
        <>
          {record.tipo_pessoa === 'pf' ? (
            <Link>{(record.nome_fantasia || record.nome)}</Link>
          ) : record.razao_social ? (
            <Link>{record.razao_social}</Link>
          ) : (
            <Link>{record.nome_fantasia}</Link>
          )}
        </>
      ),
      ellipsis: true,
    },
    {
      title: 'CPF/CNPJ',
      dataIndex: 'documento',
      render: (text, record) => (
        <>
          {record.tipo_pessoa === 'pf'
            ? mascaraCpf(record.cpf)
            : mascaraCnpj(record.cnpj)}
        </>
      ),
    },
    {
      title: 'Cidade',
      defaultSortOrder: 'descend',
      sorter: () => { },
      render: (text, record) => (
        <>
          {record.municipio && record.uf
            ? `${record.municipio} - ${record.uf}`
            : ' '}
        </>
      ),
    },
    {
      title: 'Celular',
      width: '18%',
      render: (text, record) => <>{record.phone ? record.phone : ' '}</>,
    },
    {
      title: 'Tags',
      key: 'tags',
      dataIndex: 'tags',
      render: (tags) => (
        <>
          {tags.map((tag, record) => (
            <Tag key={`tag_${tag.id}_${record.id}`}>
              {tag.title.toUpperCase()}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: 'Vendedor',
      key: 'selleruser',
      dataIndex: 'selleruser',
      render: (selleruser) => (
        <>
          {selleruser ? (
            <Tag
              icon={<UserSwitchOutlined />}
              key="selleruser.id"
              style={{ borderStyle: 'dashed' }}
            >
              {selleruser.nome}
            </Tag>
          ) : (
            <Tag
              icon={<UserSwitchOutlined />}
              key="selleruser.id"
              style={{ borderStyle: 'dashed', color: 'red' }}
            >
              Sem Vendedor
            </Tag>
          )}
        </>
      ),
    },
  ];

  function checkColumns() {
    if (width < 1100) {
      return smallColumns;
    }
    return bigColumns;
  }

  const columns = checkColumns();

  const layout = {
    layout: 'vertical',
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const menu = (
    <Menu>
      {/* <Menu.Item key="1" onClick={() => setExportClientsDrawerVisible(true)}>Exportar clientes para planilha</Menu.Item>   */}
      <Menu.Item
        key="2"
        icon={<ImportOutlined />}
        onClick={() => setImportClientsDrawerVisible(true)}
      >
        {' '}
        Importar clientes de uma planilha
      </Menu.Item>
    </Menu>
  );

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <>
      <PageHeader
        title="Clientes"
        onBack={() => navigate('/admin')}
        extra={(
          <>
            <Button
              onClick={() => {
                navigate('/admin/clientes/cadastro');
              }}
              type="primary"
            >
              Adicionar Cliente
            </Button>
            <Dropdown overlay={menu} trigger={['click']}>
              <Button type="primary">
                <>
                  Mais Ações
                  {' '}
                  <DownOutlined />
                </>
              </Button>
            </Dropdown>
          </>
        )}
      >
        <SearchCustomer />
      </PageHeader>
      <Table
        style={{ cursor: !buyerList.loading ? 'pointer' : 'auto' }}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={buyerList.loading ? [] : tableData}
        locale={{
          emptyText: buyerList.loading ? <Skeleton active /> : <Empty />,
        }}
        pagination={{
          current: buyerPagination.page,
          total: buyerPagination.totalPages * buyerPagination.perPage,
          pageSize: buyerPagination.perPage,
        }}
        onChange={(val, filters, sorter) => {
          window.scrollTo(0, 0);
          if (val.pageSize !== buyerPagination.perPage) {
            dispatch({
              type: 'SET_BUYER_PER_PAGE',
              perPage: val.pageSize,
              pageUpdated: false,
              column: sorter.column ? sorter.column.title : null,
              order: sorter.order ? sorter.order : null,
            });
            setSelectedRowKeys([]);
          } else {
            dispatch({
              type: 'SET_BUYER_PAGE',
              actualPage: val.current,
              pageUpdated: false,
              column: sorter.column ? sorter.column.title : null,
              order: sorter.order ? sorter.order : null,
            });
          }
        }}
        onRow={(record, rowIndex) => ({
          onClick: (event) => {
            navigate(`/admin/clientes/cadastro/${record.id}`);
          },
        })}
      />
      <Drawer
        title="Importar clientes de uma planilha"
        placement="right"
        size={width > 760 ? 'large' : 'small'}
        onClose={() => setImportClientsDrawerVisible(false)}
        open={importClientsDrawerVisible}
      >
        <SellerListCustomerImportClientsSheet
          drawer={importClientsDrawerVisible}
          drawerSetter={setImportClientsDrawerVisible}
        />
      </Drawer>
      <Drawer
        mask={false}
        placement="bottom"
        height={90}
        closable={false}
        headerStyle={{ display: 'none' }}
        // onClose={onClose}
        open={selectedRowKeys.length > 0}
      >
        <Row justify="center" gutter={[16, 16]}>
          <Col>
            <Button
              icon={<DeleteOutlined />}
              onClick={() => {
                const idsToDelete = selectedRowKeys.map(
                  (item) => tableData[item].id,
                );
                setDeletingBulkClients(true);
                axiosClient
                  .post(
                    '/auth/delete/buyer/bulk',
                    { buyersIds: idsToDelete },
                    {
                      withCredentials: true,
                    },
                  )
                  .then((response) => {
                    message.success('Clientes excluídos com sucesso', 4);
                    dispatch({
                      type: 'SET_BUYER_PAGE',
                      pageUpdated: false,
                    });
                    setSelectedRowKeys([]);
                    setDeletingBulkClients(false);
                  });
              }}
              loading={deletingBulkClients}
            >
              Excluir Cadastros
            </Button>
          </Col>
          <Col>
            <Button
              icon={<TagOutlined />}
              onClick={() => {
                availableTags.length > 0
                  ? setSelectTags(true)
                  : message.warning('Você ainda não cadastrou nenhuma Tag', 4);
              }}
              loading={buyerList.loading}
            >
              Aplicar Tags
            </Button>
          </Col>
          <Col>
            <Button
              icon={<UserSwitchOutlined />}
              onClick={() => {
                availableSellerUsers.length > 0
                  ? setSelectSellerUser(true)
                  : message.warning('Você não tem vendedores cadastrados', 4);
              }}
              loading={buyerList.loading}
            >
              Associar Vendedor
            </Button>
          </Col>
        </Row>
      </Drawer>
      <Drawer
        title="Selecione o vendedor"
        mask
        placement="right"
        closable
        onClose={() => {
          setSelectSellerUser(false);
          sellerUserForm.resetFields();
        }}
        open={selectSellerUser}
      >
        <Form
          form={sellerUserForm}
          scrollToFirstError
          {...layout}
          name="seller-user-form"
          initialValues={{
            keepTags: true,
          }}
          onFinish={(values) => {
            const idsToTag = selectedRowKeys.map((item) => tableData[item].id);
            setLoadingButton(true);
            axiosClient
              .post(
                '/auth/update/sellerusers/bulk',
                {
                  buyersIds: idsToTag,
                  sellerUserId: sellerUserForm.getFieldValue('selleruser')
                    ? sellerUserForm.getFieldValue('selleruser')
                    : null,
                },
                {
                  withCredentials: true,
                },
              )
              .then((response) => {
                sellerUserForm.getFieldValue('selleruser')
                  ? message.success('Vendedor vinculados com sucesso.', 4)
                  : message.success('Vendedor desvinculado com sucesso.', 4);
                dispatch({
                  type: 'SET_BUYER_PAGE',
                  pageUpdated: false,
                });
                setSelectedRowKeys([]);
                setSelectSellerUser(false);
                sellerUserForm.resetFields();
                setLoadingButton(false);
              });
          }}
        >
          <Form.Item name={['selleruser']}>
            <Select
              placeholder="Selecione o vendedor"
              allowClear
              placement="bottomRight"
            >
              {availableSellerUsers.map((selleruser) => (
                <Option key={selleruser.id} value={selleruser.id}>
                  {selleruser.nome}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <div rootStyle={{ marginTop: 30 }}>
              <Row justify="center">
                <Button
                  block
                  loading={loadingButton}
                  type="primary"
                  htmlType="submit"
                >
                  Associar Vendedor
                </Button>
              </Row>
            </div>
          </Form.Item>
        </Form>
      </Drawer>
      <Drawer
        title="Selecione as Tags"
        mask
        placement="right"
        closable
        onClose={() => {
          setSelectTags(false);
          tagForm.resetFields();
        }}
        open={selectTags}
      >
        <Form
          form={tagForm}
          scrollToFirstError
          {...layout}
          name="tag-form"
          initialValues={{
            keepTags: true,
          }}
          onFinish={(values) => {
            const idsToTag = selectedRowKeys.map((item) => tableData[item].id);
            setLoadingButton(true);
            axiosClient
              .post(
                '/auth/update/buyer-tags/bulk',
                {
                  buyersIds: idsToTag,
                  tags: tagForm.getFieldValue('tags')
                    ? tagForm.getFieldValue('tags')
                    : [],
                  keepTags: tagForm.getFieldValue('keepTags'),
                },
                {
                  withCredentials: true,
                },
              )
              .then((response) => {
                message.success('Tags aplicadas com sucesso', 4);
                dispatch({
                  type: 'SET_BUYER_PAGE',
                  pageUpdated: false,
                });
                setSelectedRowKeys([]);
                setSelectTags(false);
                tagForm.resetFields();
                setLoadingButton(false);
              });
          }}
        >
          <Form.Item name={['tags']}>
            <Select
              placeholder="Selecione as tags"
              mode="multiple"
              allowClear
              placement="bottomRight"
            >
              {availableTags.map((group, i) => (
                <OptGroup key={`group_${group.id}`} label={group.title}>
                  {group.tags.map((tag, i) => (
                    <Option
                      key={`tag_${tag.id}`}
                      value={`${group.id}_${tag.id}`}
                    >
                      {tag.title}
                    </Option>
                  ))}
                </OptGroup>
              ))}
            </Select>
          </Form.Item>
          <Space size={10}>
            <Form.Item valuePropName="checked" name="keepTags" noStyle>
              <Switch defaultChecked size="small" />
            </Form.Item>
            <span>Manter outras tags existentes</span>
          </Space>
          <Form.Item>
            <div rootStyle={{ marginTop: 30 }}>
              <Row justify="center">
                <Button
                  block
                  loading={loadingButton}
                  type="primary"
                  htmlType="submit"
                >
                  Salvar Tags
                </Button>
              </Row>
            </div>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}
