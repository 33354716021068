import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Tooltip,
  Button,
  Upload,
  Modal,
  Select,
  Divider,
  message,
} from 'antd';
// import styles from "./cadastro.module.css";
import { UploadOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import styles from './seller_profile.module.css';
import { axiosClient } from '../../apiClient';
import {
  mascaraPhone,
  mascaraCnpj,
  mascaraCpf,
  validaCpfCnpj,
} from '../../functions/validation';

const { Option } = Select;
const { Search } = Input;

export default function SellerProfile() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [tipoPessoa, setTipoPessoa] = useState('pj');
  const [loading, setLoading] = useState(false);

  const [uploadData, setUploadData] = useState({
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: [],
  });

  const [sellerData, setSellerData] = useState({});

  function getAddress(cep) {
    axiosClient
      .post(
        '/shipping/address-from-cep',
        { func: 'consultarCep', data: cep },
        { withCredentials: true },
      )

      .then((response) => {
        const data = response.data.response;
        delete data.complemento;
        data.municipio = data.localidade;
        data.endereco = data.logradouro;
        form.setFieldsValue(data);
      });
  }

  useEffect(() => {
    axiosClient
      .get('/seller-info', { withCredentials: true })
      .then((response) => {
        setTipoPessoa(response.data.tipo_pessoa);
        setSellerData(response.data);
        response.data.logo_image
          && setUploadData({
            ...uploadData,
            fileList: [
              { ...response.data.logo_image, name: 'Logotipo da Empresa' },
            ],
          });
      });
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [sellerData]);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handleCancel = () => setUploadData({ ...uploadData, previewVisible: false });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setUploadData({
      ...uploadData,
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const layout = {
    layout: 'vertical',
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const onFinish = async (values) => {
    setLoading(true);
    const { fileList } = uploadData;
    for (let i = 0; i < fileList.length; i++) {
      if (fileList[i].url === undefined) {
        fileList[i].base64 = await getBase64(fileList[i].originFileObj);
      }
    }
    axiosClient
      .post(
        '/auth/update/seller',
        { ...values, fileList },
        { withCredentials: true },
      )
      .then((response) => {
        if (response.data.in_use) {
          if (response.data.in_use.username) {
            form.setFields([
              {
                name: 'username',
                errors: [
                  'Este username já está sendo utilizado, escolha outro.',
                ],
              },
            ]);
          }
          if (response.data.in_use.email) {
            form.setFields([
              {
                name: 'email',
                errors: ['Este email já está sendo utilizado, escolha outro.'],
              },
            ]);
          }
        } else {
          message.success('Dados salvos com sucesso', 4);
          // dispatch({ type: "HOME" });
        }
        setLoading(false);
      });
  };

  const onPessoaChange = (value) => {
    setTipoPessoa(value);
  };

  const handleChange = ({ fileList }) => {
    setUploadData({ ...uploadData, fileList });
  };

  return (
    (
      <Form
        form={form}
        scrollToFirstError
        {...layout}
        name="nest-messages"
        onFinish={onFinish}
        initialValues={sellerData}
      >
        <Divider orientation="left">Dados da empresa</Divider>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="tipo_pessoa"
              label="Tipo de Pessoa"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Selecione o tipo de pessoa"
                onChange={onPessoaChange}
                disabled
                allowClear
              >
                <Option value="pj">Pessoa Jurídica</Option>
                <Option value="pf">Pessoa Física</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            {tipoPessoa === 'pf' && (
              <Form.Item
                name={['cpf']}
                label="CPF"
                // onChange={() => }

                rules={[
                  {
                    type: 'string',
                    required: true,
                    max: 100,
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            )}

            {tipoPessoa === 'pj' && (
              <Form.Item
                name={['cnpj']}
                label="CNPJ"
                // onChange={() => }

                rules={[
                  {
                    type: 'string',
                    required: true,
                    max: 100,
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
            )}
          </Col>
        </Row>
        {tipoPessoa === 'pj' && (
          <Row gutter={16}>
            <Col xs={24} sm={12}>
              <Form.Item
                name={['razao_social']}
                label="Razão Social"
                // onChange={() => }

                rules={[
                  {
                    type: 'string',
                    required: false,
                    max: 100,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                name={['ie']}
                label="Inscrição Estadual"
                // onChange={() => }

                rules={[
                  {
                    type: 'string',
                    max: 100,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name={['nome_fantasia']}
              label="Nome Fantasia da Empresa"
              // onChange={() => }

              rules={[
                {
                  type: 'string',
                  required: true,
                  max: 100,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name={['nome']}
              label="Nome do Proprietário"
              // onChange={() => }

              rules={[
                {
                  type: 'string',
                  required: true,
                  max: 100,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name={['email']}
              label="Email"
              rules={[
                {
                  type: 'email',
                  required: true,
                  max: 100,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name={['phone']}
              label="Celular ou telefone (com DDD)"
              rules={[
                {
                  type: 'string',
                  required: true,
                  max: 100,
                },
              ]}
            >
              <Input
                onChange={(e) => {
                  form.setFieldsValue({
                    phone: mascaraPhone(e),
                  });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left">Endereço</Divider>
        <Row gutter={16}>
          <Col xs={24} sm={6}>
            <Form.Item
              name={['cep']}
              label="CEP"
              // onChange={() => }

              rules={[
                {
                  type: 'string',
                  max: 100,
                },
              ]}
            >
              <Search
                onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : '')}
                onSearch={(cep) => getAddress(cep)}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name={['municipio']}
              label="Município"
              // onChange={() => }

              rules={[
                {
                  type: 'string',
                  max: 100,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={6}>
            <Form.Item
              name={['uf']}
              label="UF"
              // onChange={() => }

              rules={[
                {
                  type: 'string',
                  max: 100,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name={['endereco']}
              label="Endereço"
              rules={[
                {
                  type: 'string',
                  max: 100,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={8}>
            <Form.Item
              name={['bairro']}
              label="Bairro"
              rules={[
                {
                  type: 'string',
                  max: 100,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={6}>
            <Form.Item
              name={['numero']}
              label="Número"
              rules={[
                {
                  type: 'string',
                  max: 100,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={10}>
            <Form.Item
              name={['complemento']}
              label="Complemento"
              // onChange={() => }

              rules={[
                {
                  type: 'string',
                  max: 100,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Divider orientation="left">Endereço do Ecommerce</Divider>
        <Tooltip
          placement="topLeft"
          title="Endereço que seus clientes utilizarão para acessar o seu ecommerce"
        >
          <Form.Item
            name={['username']}
            rules={[
              {
                required: true,
                message: 'Escolha um username',
              },
              {
                message: 'Máximo de 20 letras',
                max: 20,
              },
            ]}
          >
            <Input
              style={{ width: '300px' }}
              addonBefore="www.nupedido.com.br/"
              suffix=""
              onChange={(e) => {
                form.setFieldsValue({
                  username: e.target.value
                    .normalize('NFD')
                    .replace(/\p{Diacritic}/gu, '')
                    .replace(/[^A-Z0-9]+/gi, '_')
                    .toLowerCase(),
                });
              }}
            />
          </Form.Item>
        </Tooltip>
        <Divider orientation="left">Logo da empresa</Divider>
        <Form.Item
          name="upload"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Upload
            className={styles['upload-list-inline']}
            name="logo"
            listType="picture"
            maxCount={1}
            fileList={uploadData.fileList}
            onPreview={handlePreview}
            onChange={handleChange}
            beforeUpload={() => false}
          >
            <Button icon={<UploadOutlined />}>Procurar arquivo</Button>
          </Upload>
          <Modal
            open={uploadData.previewVisible}
            title={uploadData.previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img
              alt="example"
              style={{ width: '100%' }}
              src={uploadData.previewImage}
            />
          </Modal>
        </Form.Item>
        <Form.Item>
          <div style={{ marginTop: 30 }}>
            <Row justify="center" gutter={[30, 12]}>
              <Col xs={18} sm={8}>
                <Button block type="primary" loading={loading} htmlType="submit">
                  Salvar
                </Button>
              </Col>
            </Row>
          </div>
        </Form.Item>
      </Form>
    )
  );
}
