function initialState() {
  return { buyerView: null };
}

export function buyerView(state = initialState(), action) {
  switch (action.type) {
    case 'SET_BUYER_VIEW':
      return { ...state, buyerView: action.buyerView };
    default:
      return state;
  }
}
