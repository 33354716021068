import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Table, Button, Skeleton, Empty, Typography,
} from 'antd';
// import styles from "./cadastro.module.css";
// import { useDispatch } from "react-redux";
import { axiosClient } from '../../apiClient';

const { Link } = Typography;

export default function SellerListShipping() {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axiosClient
      .post('/shipping/get', { shippingId: false }, { withCredentials: true })
      .then((response) => {
        const values = response.data.shippings.map((v) => ({
          ...v,
          key: v.id,
        }));
        setData(values);
        setLoading(false);
      });
  }, []);

  const columns = [
    {
      title: 'Descrição',
      render: (record) => <Link>{record.nome}</Link>,
      ellipsis: true,
      responsive: ['sm'],
    },
    {
      title: 'Valor',
      render: (record) => (
        <>
          {record.metodo == 'personalizado'
            ? parseFloat(record.valor).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
            : 'Cálculo automático no checkout'}
        </>
      ),
      responsive: ['sm'],
    },

    {
      title: 'Métodos',
      render: (record) => (
        <>
          <Link>{record.nome}</Link>
          <br />
          {record.metodo == 'personalizado'
            ? parseFloat(record.valor).toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
            : 'Cálculo automático no checkout'}
        </>
      ),
      responsive: ['xs'],
      ellipsis: true,
    },
  ];

  return (
    <>
      <PageHeader
        title="Métodos de Entrega"
        onBack={() => navigate('/admin')}
        extra={(
          <Button
            onClick={() => {
              navigate('/admin/entrega/cadastro');
            }}
            type="primary"
          >
            Adicionar Método de Entrega
          </Button>
        )}
      />
      <Table
        columns={columns}
        dataSource={loading ? [] : data}
        locale={{
          emptyText: loading ? <Skeleton active /> : <Empty />,
        }}
        style={{ cursor: !loading ? 'pointer' : 'auto' }}
        onRow={(record, rowIndex) => ({
          onClick: (event) => {
            navigate(`/admin/entrega/cadastro/${record.id}`);
          },
        })}
      />
    </>
  );
}
