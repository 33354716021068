function initialState() {
  return { customerId: false, productId: false, ruleId: false };
}

export function editItems(state = initialState(), action) {
  switch (action.type) {
    case 'USER_EDIT':
      return { ...state, userId: action.userId };

    default:
      return state;
  }
}
