import { IoTrashBinOutline } from 'react-icons/io5';
import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import {
  Divider, Button, Alert, List, Typography, Input, Row, Col, Select, Space, Flex, message
} from 'antd';
import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './cart.module.css';
import QtyChangerCart from '../QtyChangerCart';
import { addToCart, removeFromCart } from '../../actions/cart';
import { axiosClient } from '../../apiClient';

const { Text, Link, Title } = Typography;

export default function Cart() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myRef = useRef();
  const cartProducts = useSelector((state) => state.cart.products);
  const productsTotal = useSelector((state) => state.cart.productsTotal);
  const productsTotalAfterDiscount = useSelector((state) => state.cart.productsTotalAfterDiscount);
  const minOrderValue = useSelector((state) => state.cart.minOrderValue);
  const logged = useSelector((state) => state.authentication.logged);
  const orderRebuy = useSelector((state) => state.orderRebuy);
  const cart = useSelector((state) => state.cart);
  const sellerData = useSelector((state) => state.sellerData);
  const onlineStatus = useSelector((state) => state.sync.onlineStatus);
  const syncStatus = useSelector((state) => state.sync.syncStatus);
  const cartAuxInfo = useSelector((state) => state.cart.cartAuxInfo);

  const [failMessage, setFailMessage] = useState(false);
  const [editPrice, setEditPrice] = useState(null);
  const [newPrice, setNewPrice] = useState(null);

  const [inputValue, setInputValue] = useState('0');
  const [editDiscount, setEditDiscount] = useState(false);

  const getProductPrice = (product, qtyInCart) => {
    const priceBulk = product?.product_price_bulk;
    const bulkPrice = priceBulk && Object.entries(priceBulk).reduce((acc, [minQty, price], index, entries) => {
      const nextMinQty = entries[index + 1]?.[0];
      return qtyInCart >= minQty && (!nextMinQty || qtyInCart < nextMinQty) ? price : acc;
    }, null);
    return product.price_modified || Math.min(
      ...[bulkPrice, product.product_price_sale, product.product_price].filter(price => price != null)
    );
  };

  function dealWithSubmit(product) {
    setEditPrice(null);
    const actualPrice = product.product_price_sale ? product.product_price_sale : product.product_price;
    parseFloat(newPrice) === actualPrice ? dispatch({ type: 'REMOVE_MODIFY_PRICE', product }) : dispatch({ type: 'MODIFY_PRICE', product, price_modified: newPrice });
    dispatch({ type: 'CART_UPDATED', updated: true });
    dispatch({ type: 'CART_RETURNED', returned: false });
  }

  useEffect(() => {
    if (productsTotalAfterDiscount == 0) {
      setFailMessage('Adicione produtos no carrinho para finalizar o pedido');
    } else if (minOrderValue > productsTotalAfterDiscount) {
      setFailMessage(
        `Adicione mais R$${parseFloat(minOrderValue - productsTotalAfterDiscount).toFixed(
          2,
        )} no carrinho para atingir o pedido mínimo de R$${parseFloat(
          minOrderValue,
        ).toFixed(2)}`,
        5,
      );
    } else {
      setFailMessage(false);
    }
  }, [productsTotalAfterDiscount]);

  useEffect(() => {
    if (myRef && myRef.current) {
      const { input } = myRef.current;
      input.focus();
    }
  });

  function CartRow(props) {
    const { product } = props;

    let imgSrc = null;

    if (onlineStatus) {
      if (props.product.product_image && props.product.product_image[0]) {
        imgSrc = props.product.product_image[0].thumbnail;
      }
    } else if (onlineStatus === false && syncStatus === 'synced') {
      imgSrc = localStorage.getItem('products' + `_${props.product.id}`);
    }

    return (
      <List.Item>
        <div
          key={`card${product.product_sku}`}
          className={styles['card-item']}
        >
          <div className={styles['img-container']}>
            <img
              className={styles['product-img']}
              src={imgSrc || '/fallback.png'}
              alt=""
            />
          </div>
          <div className={styles.product_info}>
            <div className={styles.product_name}>
              {product.product_name}
            </div>
            <div className={styles['product-calc']}>
              <div >
                <span >
                  {((logged === 'seller' || (logged === 'sellerUser' && sellerData.permissions.configuration.modify_price_in_cart)) && editPrice == product.id)
                    ? (
                      <Space.Compact>
                        <Input
                          onBlur={(e) => setEditPrice(null)}
                          type="number"
                          pattern="\d*"
                          size="small"
                          prefix="R$"
                          value={newPrice}
                          style={{ width: '60%' }}
                          onChange={(e) => {
                            const val = parseInt(e.target.value.replace(/\D/g, '')) / 100;
                            setNewPrice(val.toFixed(2));
                          }}
                          ref={myRef}
                          onPressEnter={() => dealWithSubmit(product)}
                        />
                        <Button onMouseDown={() => dealWithSubmit(product)} size="small" type="primary"><CheckOutlined /></Button>
                      </Space.Compact>
                    )
                    : (
                      <div style={{ lineHeight: '110%' }}>
                        {'price_modified' in product
                          ? (
                            <>
                              <Text delete>
                                R$
                                {product.product_price_sale ? product.product_price_sale : product.product_price}
                              </Text>
                              <br />
                              <Link onClick={() => { setNewPrice(product.price_modified); setEditPrice(product.id); }}>{`R$${product.price_modified} `}</Link>
                            </>
                          )
                          : (logged === 'seller' || (logged === 'sellerUser' && sellerData.permissions.configuration.modify_price_in_cart))
                            ? (
                              <Link onClick={() => { const actualPrice = product.product_price_sale ? product.product_price_sale : product.product_price; setNewPrice(actualPrice.toFixed(2)); setEditPrice(product.id); }}>
                                R$
                                {' '}
                                {parseFloat(getProductPrice(product, product.quantity)).toFixed(2)}
                                {' '}
                              </Link>
                            )
                            : (
                              <Text>
                                {' '}
                                R$
                                {parseFloat(getProductPrice(product, product.quantity)).toFixed(2)}
                                {' '}
                              </Text>
                            )}
                        {' '}
                        x
                        {product.quantity}
                        {' '}
                        = R$
                        {parseFloat(product.quantity * getProductPrice(product, product.quantity)).toFixed(2)}
                      </div>
                    )}
                </span>
              </div>
              <QtyChangerCart
                addToCart={addToCart}
                product={product}
                quantity={product.quantity}
              />
            </div>
          </div>
          <IoTrashBinOutline
            onClick={() => {
              dispatch(removeFromCart(product));
              dispatch({ type: 'CART_UPDATED', updated: true });
              dispatch({ type: 'CART_RETURNED', returned: false });
            }}
            className={styles['trash-icon']}
          />
        </div>
      </List.Item>
    );
  }

  const updateDiscount = () => {
    const maxAllowedDiscount = logged === 'seller' ? 100 : sellerData.permissions.configuration?.max_discount_percentage;
    const discount = parseFloat(inputValue);

    if (isNaN(discount) || discount < 0) {
      message.error('Desconto não pode ser negativo ou inválido');
    } else if (discount <= maxAllowedDiscount) {
      dispatch({ type: 'SET_CART', cartAuxInfo: { sellerDiscountPercentage: discount } });
      dispatch({ type: 'CART_UPDATED', updated: true });
      dispatch({ type: 'CART_RETURNED', returned: false });
      setEditDiscount(false);
    } else {
      message.error(`Seu desconto máximo permitido é ${maxAllowedDiscount}%`);
    }
  };

  return (
    <div className={styles['order-summary']}>
      <div className={styles['summary-card']}>
        <div className={styles['product-list']}>
          <List
            itemLayout="vertical"
            dataSource={cartProducts}
            renderItem={(product) => (
              <CartRow product={product} />
            )}
          />
        </div>
        <Divider />
        {cartAuxInfo.sellerDiscountPercentage ?
          <div className={styles.order_total}>
            <div>
              Total dos produtos
            </div>
            <div>
              <Title style={{ margin: 0, padding: 0 }} level={5}>
                R$
                {parseFloat(productsTotal).toFixed(2)}
              </Title>
            </div>
          </div> : <></>}
        {(logged === 'seller' || (logged === 'sellerUser' && sellerData.permissions.configuration?.max_discount_percentage > 0)) && <>
          {!editDiscount &&
            <div className={styles.order_total}>
              <p>
                <Link onClick={() => setEditDiscount(true)}>
                  {cartAuxInfo.sellerDiscountPercentage ? <>Desconto (
                    <>-{cartAuxInfo.sellerDiscountPercentage}%</>
                    )</> : <>Adicionar desconto</>}
                </Link>
              </p>
              <div>
                <h4>
                  {cartAuxInfo.sellerDiscountPercentage ? <>- R$ {(cartAuxInfo.sellerDiscountPercentage / 100 * productsTotal).toFixed(2)}</> : <></>}
                </h4>
              </div>
            </div>}

          {editDiscount && <div className={styles.order_total}>
            <p><Input.Group style={{ display: "flex", justifyContent: "end" }} compact>
              <Select disabled style={{ width: "110px" }} size="small" defaultValue="Desconto" options={[
                {
                  value: 'discount',
                  label: 'Desconto',
                },
                // {
                //   value: 'add',
                //   label: 'Acréscimo',
                // },
              ]} />
              <Input
                size="small"
                style={{ width: '80px' }}
                suffix="%"
                value={inputValue}
                onChange={(e) => {
                  let value = e.target.value;
                  if (/^\d*\.?\d*$/.test(value)) {
                    value = value.replace(/^0+(?![\.|$])/, '');
                    setInputValue(value === '' ? '0' : value);
                  }
                }}
                onKeyDown={(e) => e.key === 'Enter' && updateDiscount()}
              />
              <Button size="small" type="primary" onClick={updateDiscount}><CheckOutlined /></Button>
            </Input.Group></p>
            <div>
              <h4>

              </h4>
            </div>
          </div>
          }
        </>}
        <Space direction="vertical">
          <Flex justify="space-between">
            <Title style={{ margin: 0, padding: 0 }} level={4}>Total</Title>
            <Title style={{ margin: 0, padding: 0 }} level={4}>
              R$
              {parseFloat(productsTotalAfterDiscount).toFixed(2)}
            </Title>
          </Flex>

          <Space style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }} direction="vertical">
            {failMessage && <Alert message={failMessage} type="info" />}
            <Button
              size="large"
              type="primary"
              disabled={!!failMessage}
              onClick={() => {
                if (!orderRebuy.orderRebuyId) {
                  failMessage == false && dispatch({ type: 'GO_CHECKOUT' });
                } else {
                  axiosClient
                    .post(
                      '/cart/order/update',
                      { orderId: orderRebuy.orderRebuyId, cart },
                      { withCredentials: true },
                    )
                    .then((response) => {
                      if (response.data.status === 'Success') {
                        dispatch({ type: 'RESET_REBUY' });
                        navigate(`/${sellerData.username}/checkout/${orderRebuy.orderRebuyId}`);
                      }
                    });
                }
              }}
            >
              Finalizar Pedido
            </Button>
          </Space>
        </Space>
      </div>

      <div />
    </div>
  );
}
