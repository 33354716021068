function initialState() {
  return { bills: null };
}

export function bills(state = initialState(), action) {
  switch (action.type) {
    case 'SET_BILLS':
      return { ...state, bills: action.bills };
    default:
      return state;
  }
}
