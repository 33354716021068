function initialState() {
  return ({ orderRebuyId: null, buyerName: null, orderType: null });
}

export function orderRebuy(state = initialState(), action) {
  switch (action.type) {
    case 'SET_ORDER_REBUY':
      return { ...state, orderRebuyId: action.orderRebuyId };
    case 'SET_REBUY_INFO':
      return { ...state, buyerName: action.buyerName, orderType: action.orderType };
    case 'RESET_REBUY':
      return initialState();
    default:
      return state;
  }
}
