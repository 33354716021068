import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input } from 'antd';
import { debounce } from 'lodash';
import styles from './search.module.css';
import { axiosClient } from '../../apiClient';

export default function SearchCustomer() {
  const dispatch = useDispatch();
  const buyerPagination = useSelector((state) => state.buyerPagination);
  const searchTermValue = useSelector(
    (state) => state.buyerSearchState.searchTerm,
  );
  useEffect(() => { dispatch({ type: 'SET_BUYER_LOADING', loading: true }); }, []);

  const updateSearch = async (page, perPage, searchTerm, column, order, resetPage) => {
    try {
      dispatch({ type: 'SET_BUYER_LOADING', loading: true });
      const results = await axiosClient.post('/auth/search/buyer', {
        search: searchTerm,
        page: resetPage ? 1 : page,
        perPage,
        column,
        order,
      }, { withCredentials: true });
      dispatch({ type: 'SET_BUYER_LIST', list: results.data.buyers });
      dispatch({ type: 'SET_BUYER_LOADING', loading: false });
      dispatch({
        type: 'SET_BUYER_PAGE',
        totalPages: results.data.total_pages,
        actualPage: results.data.actual_page,
        column: results.data.column,
        order: results.data.order,
        pageUpdated: true,
      });
    } catch (error) { }
  };

  const updateSearchHandler = useCallback(debounce(updateSearch, 500), []);

  const handleSearchChange = async (e) => {
    dispatch({ type: 'SET_BUYER_SEARCH_TERM', searchTerm: e.target.value });
  };

  useEffect(() => {
    updateSearchHandler(
      buyerPagination.page,
      buyerPagination.perPage,
      searchTermValue,
      buyerPagination.column,
      buyerPagination.order,
      true,
    );
  }, [searchTermValue, buyerPagination.perPage, buyerPagination.column, buyerPagination.order]);

  useEffect(() => {
    !buyerPagination.pageUpdated
      && updateSearchHandler(
        buyerPagination.page,
        buyerPagination.perPage,
        searchTermValue,
        buyerPagination.column,
        buyerPagination.order,
        false,
      );
  }, [buyerPagination.page]);

  return (
    <div className={styles['search-container']}>
      <Input
        defaultValue={searchTermValue}
        allowClear
        size="large"
        type="text"
        placeholder="Nome, documento, cidade, e-mail..."
        onChange={handleSearchChange}
        onFocus={(e) => dispatch({ type: 'SET_BUYER_SEARCH_FOCUS', onFocus: true })}
        onBlur={(e) => dispatch({ type: 'SET_BUYER_SEARCH_FOCUS', onFocus: false })}
      />
    </div>
  );
}
