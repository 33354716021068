import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'antd';
import {
  PlusOutlined,
  MinusOutlined,
} from '@ant-design/icons';
import styles from './qtychanger.module.css';

export default function QtyChangerCart(props) {
  const { addToCart, product } = props;

  const cartProducts = useSelector((state) => state.cart.products);
  const quantity = useSelector((state) => state.pageQty.data);

  const filteredCart = cartProducts.filter((obj) => obj.product_sku === product.product_sku);

  const filteredQty = quantity.filter((obj) => obj.product_sku === product.product_sku);

  const qtyInPage = filteredQty[0] ? filteredQty[0].quantity : 0;
  const qtyInCart = filteredCart[0] ? filteredCart[0].quantity : 0;

  const dispatch = useDispatch();
  return (
    <div className={styles['pqt-geral']}>
      <Button
        size="small"
        onClick={() => {
          dispatch(addToCart(product, qtyInPage, qtyInCart, -1));
          dispatch({ type: 'CART_UPDATED', updated: true });
          dispatch({ type: 'CART_RETURNED', returned: false });
        }}
        icon={<MinusOutlined />}
      />

      <span className={styles.pqt}>{qtyInCart}</span>
      <Button
        size="small"
        onClick={() => {
          dispatch(addToCart(product, qtyInPage, qtyInCart, 1));
          dispatch({ type: 'CART_UPDATED', updated: true });
          dispatch({ type: 'CART_RETURNED', returned: false });
        }}
        icon={<PlusOutlined />}
      />
    </div>
  );
}
