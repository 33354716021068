function initialState() {
  return { buyers: [] };
}

export function buyers(state = initialState(), action) {
  switch (action.type) {
    case 'SET_BUYERS':
      return { ...state, buyers: action.buyers };
    default:
      return state;
  }
}
