import React, { useEffect, useState } from 'react';
import { TreeSelect } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import styles from './dropdown.module.css';
import { axiosClient } from '../../apiClient';

const { SHOW_PARENT } = TreeSelect;

export default function Dropdown() {
  const dispatch = useDispatch();
  const dropdownValue = useSelector((state) => state.dropdown.value);
  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    axiosClient
      .get('/products/categories/list', { withCredentials: true })
      .then((response) => {
        setTreeData(response.data);
      });
  }, []);

  const onDropChange = (value) => {
    dispatch({ type: 'DROPDOWN_VALUE', value });
  };

  return (
    <div className={styles['dropdown-container']}>
      <TreeSelect
        treeData={treeData}
        value={dropdownValue}
        onChange={onDropChange}
        treeCheckable
        showCheckedStrategy={SHOW_PARENT}
        placeholder="Filtre por categorias"
        className={styles.dropdown}
        size="large"
        showSearch={false}
        allowClear
      />
    </div>
  );
}
