import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import {
  Table,
  Button,
  Image,
  Skeleton,
  Empty,
  Drawer,
  Tooltip,
  Row,
  Col,
  message,
  Form,
  Select,
  Space,
  Switch,
  TreeSelect,
  Tag,
  Typography,
  Menu,
  Upload,
  Dropdown,
  Progress,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { reverse } from 'lodash';
import axios from 'axios';
import {
  DeleteOutlined, TagOutlined, MenuOutlined, ImportOutlined, DownOutlined, UploadOutlined,
} from '@ant-design/icons';
import { axiosClient } from '../../apiClient';
import SearchProduct from '../Search';

const { Text, Link } = Typography;

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default function SellerListProduct() {
  const XLSX = require('xlsx');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [tagForm] = Form.useForm();
  const [CategoryForm] = Form.useForm();
  const { Option, OptGroup } = Select;
  const [width, height] = useWindowSize();
  const [imageUrls, setImageUrls] = useState([]);
  const [visible, setVisible] = useState(false);
  const [filteredProds, setFilteredProds] = useState([]);
  const [allProds, setAllProds] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [selectTags, setSelectTags] = useState(false);
  const [selectCategories, setSelectCategories] = useState(false);
  const [deletingBulkProducts, setDeletingBulkProducts] = useState(false);
  const productList = useSelector((state) => state.productList);
  const totalPages = useSelector((state) => state.pagination.totalPages);
  const page = useSelector((state) => state.pagination.page);
  const perPage = useSelector((state) => state.pagination.perPage);

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [drawerName, setDrawerName] = useState('');
  const [clickedFatherId, setClickedFatherId] = useState(null);

  const [sheetModel, setSheetModel] = useState('tiny');
  const [sheetType, setSheetTypel] = useState('xls');
  const [importProductsDrawerVisible, setImportProductsDrawerVisible] = useState(false);
  const [importingProducts, setImportingProducts] = useState(false);
  const [importProductsPercentage, setImportProductsPercentage] = useState(0);
  const [stopImportFile, setStopImportFile] = useState(false);

  function ImageComp(props) {
    return (
      <Image
        preview={
          props.product.product_image && props.product.product_image[0]
            ? { visible: false }
            : false
        }
        style={{ maxWidth: 60, maxHeight: 60 }}
        src={
          props.product.product_image?.[0]?.thumbnail ?? '/fallback.png'
        }
        onClick={() => {
          if (props.product.product_image && props.product.product_image[0]) {
            setImageUrls(props.product.product_image.map((x) => x.url));
            setVisible(true);
          }
        }}
      />
    );
  }

  useEffect(() => {
    axiosClient
      .get('/products/categories/list', { withCredentials: true })
      .then((response) => {
        setTreeData(response.data);
      });
  }, []);

  useEffect(() => {
    axiosClient
      .get('/products/tag/list', { withCredentials: true })
      .then((out) => {
        setAvailableTags(out.data.tags);
      });
  }, []);

  const findPath = (tree, id, pathStack = []) => {
    if (tree.id === id) {
      pathStack.push(tree.title);
      return pathStack.slice(1).join(' > ');
    }

    pathStack.push(tree.title);

    if (tree.children) {
      for (const node of tree.children) {
        const result = findPath(node, id, [...pathStack]);
        if (result) return result;
      }
    }
  };

  useEffect(() => {
    const prods = [];
    const prodData = productList.list.map((obj, index) => ({
      ...obj,
      key: index,
      categories: findPath(
        { id: -1, children: treeData, title: '' },
        obj.category_id,
      ),
    }));
    prodData.forEach((product) => {
      if (product.variation_type == 'P' && product.variation_childs) {
        prods.push({
          ...product,
          product_name: `${product.product_name} (${product.variation_childs.length} variações)`,
        });
      }
      product.variation_type == 'N' && prods.push(product);
    });
    setAllProds(prodData);
    setFilteredProds(prods);
  }, [productList.list]);

  useEffect(() => {
    dispatch({ type: 'SET_LOADING', loading: true });
  }, []);

  function productClicked(event, product) {
    if (
      event.target.className != 'ant-image-mask-info'
      && event.target.className != 'ant-image-mask'
      && event.target.hasAttribute('data-icon') == false
    ) {
      if (product.variation_type == 'N') {
        navigate(`/admin/produtos/cadastro/${product.id}`);
      } else if (product.variation_type == 'P') {
        const table_var = [];
        for (const prod in allProds) {
          if (product.variation_childs.includes(allProds[prod].id)) {
            table_var.push(allProds[prod]);
          }
        }
        setClickedFatherId(product.id);
        setTableData(table_var);
        setDrawerName(product.product_name);
        setDrawerVisible(true);
      } else if (product.length == 2) {
        navigate(`/admin/produtos/cadastro/${product[0]}`);
      }
    }
  }

  function get_stock(product) {
    if (product.variation_type == 'N' || product.variation_type == 'V') return product.product_stock;
    if (product.variation_type == 'P') {
      let total_stock = 0;
      const stock_tooltip = [];
      for (const i in product.variation_childs) {
        for (var j in productList.list) {
          if (productList.list[j].id == product.variation_childs[i]) {
            total_stock += productList.list[j].product_stock;
            const variations = Object.keys(productList.list[j].variations).map(
              (key) => productList.list[j].variations[key],
            );
            stock_tooltip.push(
              `${reverse(variations).join(' - ')}: ${productList.list[j].product_stock
              }`,
            );
          }
        }
      }
      return (
        <Tooltip
          placement="left"
          title={stock_tooltip.map((item) => (
            <>
              {item}
              <br />
            </>
          ))}
        >
          <span>{total_stock}</span>
        </Tooltip>
      );
    }
  }

  const importProducts = (values) => {
    if (values.sheet_file.file !== null) {
      const { file } = values.sheet_file;
      setImportingProducts(true);

      const reader = new FileReader();
      reader.onload = async function (e) {
        const data = e.target.result;
        const workbook = XLSX.read(data, {
          type: 'binary',
          codepage: 65001,
        });
        const products_json = XLSX.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]],
        );
        const to_upload = products_json.length;
        let uploaded = 0;

        async function send_products(products_json) {
          const promises = products_json.map(async (product_json) => await axiosClient
            .post(
              `/integrations/${sheetModel}/products-import`,
              {
                product: product_json,
              },
              { withCredentials: true },
            )
            .then((response) => {
              if (response.data.status == 'Error') {
                console.log(response.data.data);
              }
            })
            .catch((error) => {
              console.log('ERROR!!!');
            }));
          return await axios.all(promises);
        }
        for (let i = 0; i < products_json.length; i += 1) {
          const start = new Date();
          if (stopImportFile === false) {
            if (products_json[i]['Tipo do produto'] === 'V') {
              const father_sku = products_json[i]['Código (SKU)'];
              var products_pack_json = products_json[i];
              products_pack_json.variations = [];
              uploaded += 1;
              for (let j = i + 1; j < products_json.length; j += 1) {
                if (products_json[j]['Código do pai'] !== father_sku) {
                  break;
                }
                products_pack_json.variations.push(products_json[j]);
                i += 1;
                uploaded += 1;
              }
            } else {
              var products_pack_json = [];
              products_pack_json.push(products_json[i]);
              uploaded += 1;
            }
            await send_products(products_pack_json);
            setImportProductsPercentage(parseInt((uploaded * 100) / to_upload));
          }
          const end = new Date();
          console.log(`${uploaded}/${to_upload}: ${end - start} msec`);
        }
        uploaded === to_upload
          ? message.success('Produtos importados com sucesso', 4)
          : message.warning(
            `Atenção! ${to_upload - uploaded
            } produtos não foram adicionados. Tente novamente`,
            4,
          );
        dispatch({
          type: 'SET_PAGE',
          pageUpdated: false,
        });
        setSelectedRowKeys([]);
        setImportingProducts(false);
        setImportProductsPercentage(0);
        setImportProductsDrawerVisible(false);
      };
      reader.onerror = function (ex) {
        console.log(ex);
      };
      reader.readAsBinaryString(file);
    } else {
      message.error('Você deve primeiro selecionar o arquivo da planilha', 4);
      setImportingProducts(false);
    }
  };

  const downloadModelSheet = () => {
    axiosClient
      .post(
        '/integrations/download-clients-import-sheet',
        {
          erp: sheetModel,
          file_type: sheetType,
        },
        {
          withCredentials: true,
          responseType: 'blob',
        },
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `modelo_${sheetModel}.${sheetType}`);
        document.body.appendChild(link);
        link.click();
      });
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  function handleChange(values) {
    if (values.length > 1) {
      const [groupId, tagId] = values[values.length - 1].split('_');

      for (let i = 0; i < values.length - 1; i++) {
        if (values[i].startsWith(groupId)) {
          values.splice(i, 1);
        }
      }
    }
    tagForm.setFieldsValue({ tags: values });
  }

  const bigColumns = [
    {
      title: 'Imagem',
      dataIndex: 'product_image',
      key: 'product_image',
      align: 'center',
      render: (text, product) => <ImageComp product={product} />,
    },
    {
      title: 'Descrição',
      dataIndex: 'product_name',
      key: 'name',
      render: (text, product) => (
        <>
          <Link>{text}</Link>
          <br />
          {product.categories && (
            <Text type="secondary">{product.categories}</Text>
          )}
        </>
      ),
      ellipsis: true,
      width: '35%',
    },
    {
      title: 'Código',
      dataIndex: 'product_sku',
      key: 'preco',
      align: 'center',
    },
    {
      title: 'Estoque',
      dataIndex: 'product_stock',
      key: 'stock',
      align: 'center',
      render: (text, product) => get_stock(product),
    },
    {
      title: 'Preço',
      dataIndex: 'product_price',
      key: 'preco',
      align: 'center',
      render: (text, product) => (
        <>
          {product.product_price_sale ? (
            <>
              <p style={{ textDecoration: 'line-through' }}>
                {`R$ ${parseFloat(text).toFixed(2)}`}
              </p>
              <h3>
                {`R$ ${parseFloat(product.product_price_sale).toFixed(2)}`}
              </h3>
            </>
          ) : (
            <h3>{`R$ ${parseFloat(product.product_price).toFixed(2)}`}</h3>
          )}
        </>
      ),
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      align: 'center',
      render: (tags) => (
        <>
          {tags
            && tags.map((tag) => <Tag key={tag}>{tag.title.toUpperCase()}</Tag>)}
        </>
      ),
    },
  ];

  const smallColumns = [
    {
      title: 'Produtos',
      render: (product) => (
        <>
          <ImageComp product={product} />
          <br />
          <a
            onClick={(event) => {
              productClicked(event, product);
            }}
          >
            {' '}
            {product.product_name}
            {' '}
          </a>
          <br />
          {`R$ ${parseFloat(product.product_price).toFixed(2)}`}
          <br />
          {product.tags.map((tag) => <Tag key={tag}>{tag.title.toUpperCase()}</Tag>)}
        </>
      ),
    },
  ];

  function checkColumns() {
    if (width < 1100) {
      return smallColumns;
    }
    return bigColumns;
  }

  const columns = checkColumns();

  const columnsVariationBig = [
    {
      title: 'Imagem',
      dataIndex: 'product_image',
      key: 'product_image',
      align: 'center',
      render: (text, product) => <ImageComp product={product} />,
    },
    {
      title: 'Descrição',
      dataIndex: 'product_name',
      key: 'name',
      render: (text, product) => (
        <a
          onClick={(event) => {
            productClicked(event, product);
          }}
        >
          {' '}
          {text}
          {' '}
        </a>
      ),
      ellipsis: true,
      width: '50%',
    },
    {
      title: 'Código',
      dataIndex: 'product_sku',
      key: 'preco',
      align: 'center',
    },
    {
      title: 'Estoque',
      dataIndex: 'product_stock',
      key: 'stock',
      align: 'center',
    },
    {
      title: 'Preço',
      dataIndex: 'product_price',
      key: 'preco',
      render: (text, product) => (
        <>
          {product.product_price_sale ? (
            <>
              <p style={{ textDecoration: 'line-through' }}>
                {`R$ ${parseFloat(text).toFixed(2)}`}
              </p>
              <h3>
                {`R$ ${parseFloat(product.product_price_sale).toFixed(2)}`}
              </h3>
            </>
          ) : (
            <h3>{`R$ ${parseFloat(product.product_price).toFixed(2)}`}</h3>
          )}
        </>
      ),
      align: 'center',
    }];

  const columnsVariationSmall = [
    {
      title: 'Variações',
      render: (text, product) => (
        <>
          <ImageComp product={product} />
          <br />
          <a
            onClick={(event) => {
              productClicked(event, product);
            }}
          >
            {' '}
            {product.product_name}
            {' '}
          </a>
          <br />
          {` Estoque: ${product.product_stock}`}
          <br />
          {product.product_price_sale ? (
            <>
              <p style={{ textDecoration: 'line-through' }}>
                {`R$ ${parseFloat(product.product_price).toFixed(2)}`}
              </p>
              <h3>
                {`R$ ${parseFloat(product.product_price_sale).toFixed(2)}`}
              </h3>
            </>
          ) : (
            <h3>{`R$ ${parseFloat(product.product_price).toFixed(2)}`}</h3>
          )}
        </>
      ),
    },
  ];

  function checkVariationsColumns() {
    if (width < 1100) {
      return columnsVariationSmall;
    }
    return columnsVariationBig;
  }

  const variationColumns = checkVariationsColumns();

  const routes = [
    {
      path: '/admin',
      breadcrumbName: 'Início',
    },
    {
      path: '/admin/produtos/lista',
      breadcrumbName: 'Produtos',
    },
    {
      path: '/admin/produtos/lista',
      breadcrumbName: 'Cadastros',
    },
  ];

  const layout = {
    layout: 'vertical',
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="2"
        icon={<ImportOutlined />}
        onClick={() => setImportProductsDrawerVisible(true)}
      >
        {' '}
        Importar produtos de uma planilha
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <PageHeader
        onBack={() => navigate('/admin')}
        title="Produtos"
        extra={(
          <>
            <Button
              onClick={() => {
                navigate('/admin/produtos/cadastro');
              }}
              type="primary"
            >
              Adicionar Produto
            </Button>
            <Dropdown overlay={menu} trigger={['click']}>
              <Button type="primary">
                <>
                  Mais Ações
                  {' '}
                  <DownOutlined />
                </>
              </Button>
            </Dropdown>
          </>
        )}
      >
        <SearchProduct dontUseBuyerView dontUseOffline />
      </PageHeader>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}
      />
      {
        productList.loading ? (
          <Skeleton active />
        ) : (
          <Table
            rowSelection={rowSelection}
            columns={columns}
            expandIconColumnIndex={8}
            style={{ cursor: !productList.loading ? 'pointer' : 'auto' }}
            pagination={{
              current: page,
              total: totalPages * perPage,
              pageSize: perPage,
            }}
            // rowSelection={"checkbox"}
            expandable={{
              expandedRowRender: (product) => (
                <>
                  {product.gtin ? `GTIN: ${product.gtin}` : 'GTIN não registrado'}
                </>
              ),
              rowExpandable: (product) => product.name !== 'Not Expandable',
            }}
            onChange={(val) => {
              window.scrollTo(0, 0);
              if (val.pageSize !== perPage) {
                dispatch({
                  type: 'SET_PER_PAGE',
                  perPage: val.pageSize,
                  pageUpdated: false,
                });
              } else {
                dispatch({
                  type: 'SET_PAGE',
                  actualPage: val.current,
                  pageUpdated: false,
                });
              }
            }}
            dataSource={filteredProds}
            locale={{
              emptyText: <Empty />,
            }}
            onRow={(product, rowIndex) => ({
              onClick: (event) => {
                productClicked(event, product);
              },
            })}
          />
        )
      }
      <div style={{ display: 'none' }}>
        <Image.PreviewGroup
          preview={{
            visible,
            onVisibleChange: (vis) => {
              setVisible(vis);
              ~vis && setImageUrls([]);
            },
          }}
        >
          {imageUrls.map((x, index) => (
            <Image key={index} src={x} />
          ))}
        </Image.PreviewGroup>
      </div>
      <Drawer
        title="Importar produtos de uma planilha"
        placement="right"
        size={width > 760 ? 'large' : 'small'}
        onClose={() => setImportProductsDrawerVisible(false)}
        open={importProductsDrawerVisible}
      >
        <Form
          form={form}
          scrollToFirstError
          {...layout}
          name="nest-messages"
          onFinish={importProducts}
          initialValues={{
            sheet_model: 'tiny',
            sheet_file: null,
          }}
        >
          <Form.Item name={['sheet_model']} label="Modelo da planilha">
            <Select
              block
              defaultValue="tiny"
              onChange={(value) => setSheetModel(value)}
            >
              {/* <Option value="padrao">Padrão</Option> */}
              <Option value="tiny">Tiny ERP</Option>
              <Option value="bling">Bling ERP</Option>
            </Select>
            <span> Formatos aceitos: xls, xlsx, csv</span>
            <Button type="link" onClick={() => downloadModelSheet()}>
              Baixar modelo
            </Button>
          </Form.Item>

          <div rootStyle={{ marginTop: 30 }}>
            <Space size={10}>
              <Form.Item
                valuePropName="importSheet"
                name={['sheet_file']}
                noStyle
              >
                <Upload
                  // onChange={normFile}
                  progress
                  accept=".xls, .xlsx, .csv"
                  // showUploadList={false}
                  maxCount={1}
                  beforeUpload={() => false}
                >
                  <Button>
                    <UploadOutlined />
                    {' '}
                    Selecionar Planilha
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item
                valuePropName="sheetModel"
                name={['sheet_model']}
                noStyle
              />
            </Space>
          </div>
          <Form.Item>
            <div rootStyle={{ marginTop: 30 }}>
              <Row justify="center">
                <Button
                  block
                  loading={importingProducts}
                  type="primary"
                  htmlType="submit"
                >
                  Importar
                </Button>
              </Row>
            </div>
          </Form.Item>
        </Form>
        {importingProducts == true && (
          <Progress percent={importProductsPercentage} />
        )}
      </Drawer>
      <Drawer
        title="Variações do produto"
        placement="right"
        width={width <= 575 ? '90%' : '70%'}
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
      >
        <PageHeader
          title={drawerName}
          extra={(
            <Button
              onClick={() => {
                navigate(`/admin/produtos/cadastro/${clickedFatherId}`);
              }}
              type="primary"
            >
              Editar Produto
            </Button>
          )}
        />
        <Table
          columns={variationColumns}
          dataSource={tableData}
          onRow={(variation, rowIndex) => ({
            onClick: (event) => {
              productClicked(event, [variation.id, clickedFatherId]);
            },
          })}
        />
      </Drawer>
      <Drawer
        mask={false}
        placement="bottom"
        height={90}
        closable={false}
        headerStyle={{ display: 'none' }}
        // onClose={onClose}
        open={selectedRowKeys.length > 0}
      >
        <Row justify="center" gutter={[16, 16]}>
          <Col>
            <Button
              icon={<DeleteOutlined />}
              onClick={() => {
                const idsToDelete = selectedRowKeys.map(
                  (item) => productList.list[item].id,
                );
                setDeletingBulkProducts(true);
                axiosClient
                  .post(
                    '/products/delete/bulk',
                    { productIds: idsToDelete },
                    {
                      withCredentials: true,
                    },
                  )
                  .then((response) => {
                    message.success('Produtos excluídos com sucesso', 4);
                    dispatch({
                      type: 'SET_PAGE',
                      pageUpdated: false,
                    });
                    setSelectedRowKeys([]);
                    setDeletingBulkProducts(false);
                  });
              }}
              loading={deletingBulkProducts}
            >
              Excluir Cadastros
            </Button>
          </Col>
          <Col>
            <Button
              icon={<TagOutlined />}
              onClick={() => {
                availableTags.length > 0
                  ? setSelectTags(true)
                  : message.warning('Você ainda não cadastrou nenhuma Tag', 4);
              }}
              loading={productList.loading}
            >
              Aplicar Tags
            </Button>
          </Col>
          <Col>
            <Button
              icon={<MenuOutlined />}
              onClick={() => {
                setSelectCategories(true);
              }}
            >
              Aplicar Categorias
            </Button>
          </Col>
        </Row>
      </Drawer>
      <Drawer
        title="Selecione as Categorias"
        mask
        placement="right"
        closable
        onClose={() => {
          setSelectCategories(false);
          // tagForm.resetFields();
        }}
        open={selectCategories}
      >
        <Form
          form={CategoryForm}
          scrollToFirstError
          {...layout}
          name="category-form"
          initialValues={{
            keepOld: true,
          }}
          onFinish={(values) => {
            const idsToTag = selectedRowKeys.map(
              (item) => productList.list[item].id,
            );
            axiosClient
              .post(
                '/products/categories/bulk',
                {
                  productIds: idsToTag,
                  categoryId: CategoryForm.getFieldValue('categoryId'),
                },
                {
                  withCredentials: true,
                },
              )
              .then((response) => {
                message.success('Categorias aplicadas com sucesso', 4);
                dispatch({
                  type: 'SET_PAGE',
                  pageUpdated: false,
                });
                setSelectedRowKeys([]);
                setSelectCategories(false);
                CategoryForm.resetFields();
              });
          }}
        >
          <Form.Item name={['categoryId']}>
            <TreeSelect
              treeLine
              rootStyle={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={treeData}
              placeholder="Selecione uma categoria"
              treeDefaultExpandAll
            />
          </Form.Item>
          <Form.Item>
            <div rootStyle={{ marginTop: 30 }}>
              <Row justify="center">
                <Button block type="primary" htmlType="submit">
                  Salvar Categorias
                </Button>
              </Row>
            </div>
          </Form.Item>
        </Form>
      </Drawer>
      <Drawer
        title="Selecione as Tags"
        mask
        placement="right"
        closable
        onClose={() => {
          setSelectTags(false);
          tagForm.resetFields();
        }}
        open={selectTags}
      >
        <Form
          form={tagForm}
          scrollToFirstError
          {...layout}
          name="tag-form"
          initialValues={{
            keepOld: true,
          }}
          onFinish={(values) => {
            const idsToTag = selectedRowKeys.map(
              (item) => productList.list[item].id,
            );
            axiosClient
              .post(
                '/products/tag/bulk',
                {
                  productIds: idsToTag,
                  tags: tagForm.getFieldValue('tags')
                    ? tagForm.getFieldValue('tags')
                    : [],
                  keepTags: tagForm.getFieldValue('keepOld'),
                },
                {
                  withCredentials: true,
                },
              )
              .then((response) => {
                message.success('Tags aplicadas com sucesso', 4);
                dispatch({
                  type: 'SET_PAGE',
                  pageUpdated: false,
                });
                setSelectedRowKeys([]);
                setSelectTags(false);
                tagForm.resetFields();
              });
          }}
        >
          <Form.Item name={['tags']}>
            <Select
              placeholder="Selecione as tags"
              onChange={handleChange}
              mode="multiple"
              allowClear
              placement="bottomRight"
            >
              {availableTags.map((group, i) => (
                <OptGroup key={`group_${group.id}`} label={group.title}>
                  {group.tags.map((tag, i) => (
                    <Option
                      key={`tag_${tag.id}`}
                      value={`${group.id}_${tag.id}`}
                    >
                      {tag.title}
                    </Option>
                  ))}
                </OptGroup>
              ))}
            </Select>
          </Form.Item>
          <Space size={10}>
            <Form.Item valuePropName="checked" name="keepOld" noStyle>
              <Switch defaultChecked size="small" />
            </Form.Item>
            <span>Manter outras tags existentes</span>
          </Space>
          <Form.Item>
            <div rootStyle={{ marginTop: 30 }}>
              <Row justify="center">
                <Button block type="primary" htmlType="submit">
                  Salvar Tags
                </Button>
              </Row>
            </div>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}
