export function applyBulkDiscount(rules, products) {
    products.forEach(product => {
        rules.forEach(rule => {
            // if (rule.action === "progressiveDiscount" && rule.is_active) {
            if (rule.action === "progressiveDiscount") {
                const appliesToProduct = rule.all_products || rule.products.includes(product.id) || rule.products_categories.includes(product.category_id);

                if (appliesToProduct) {
                    const progressiveTable = rule.configuration?.progressiveTable || [];
                    const bulkPrices = {};

                    progressiveTable.forEach(entry => {
                        const quantity = parseInt(entry.quantidade, 10);
                        const discountPercentage = parseFloat(entry.porcentagem) / 100;

                        if (!isNaN(quantity) && !isNaN(discountPercentage)) {
                            const discountedPrice = parseFloat((product.product_price * (1 - discountPercentage)).toFixed(2));
                            bulkPrices[quantity] = Math.min(
                                ...[discountedPrice, product.product_price_sale].filter(price => price != null)
                            );;
                        }
                    });

                    if (Object.keys(bulkPrices).length > 0) {
                        product.product_price_bulk = bulkPrices;
                    }
                }
            }
        });
    });

    return products;
}